import { LinkIcon } from "components/icons"
import { SmallFileViewer, TitleValueViewer } from "components/shared"
import { AttachmentType } from "types/attachment"
import { Stack, Typography } from "@mui/material"
import { t } from "i18next"
import React, { useEffect, useState } from "react"

type Props = {
  attachments?: AttachmentType[]
}

const ActivityAttachments = ({ attachments }: Props) => {
  const [files, setFiles] = useState<AttachmentType[]>([])

  useEffect(() => {
    if (attachments !== undefined) {
      setFiles(attachments)
    }
  }, [attachments])

  let attachmentsView = null

  if (files.length === 0) {
    attachmentsView = (
      <Typography variant="smallMedium">There is no any attachments</Typography>
    )
  }

  if (files.length > 0) {
    attachmentsView = (
      <Stack
        direction={"row"}
        sx={{
          width: "100%",
          flexWrap: "wrap",
          "& > *": { m: 1 },
          "&>:first-child": { ml: 0 },
        }}
      >
        {files.map((file) => {
          return <SmallFileViewer key={file.id} file={file} />
        })}
      </Stack>
    )
  }

  return (
    <TitleValueViewer
      direction="column"
      titleVariant="bodyMedium"
      value={attachmentsView}
      title={t("attachments")}
      icon={<LinkIcon />}
    />
  )
}

export default ActivityAttachments
