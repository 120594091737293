import axios from "axios"

/**
 * axios instance and interceptors are created here.
 * Will subscribe to redux store later on.
 */
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    Accept: "application/json",
  },
})

export default api
