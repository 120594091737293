import { LoadingButton } from "@mui/lab"
import { Skeleton, Stack, Typography } from "@mui/material"
import { MAIN_CONTRACT_ADDRESS, READ_CONTRACT_ADDRESS } from "consistants"
import { useLoading, useSettings } from "hooks"
import { t } from "i18next"
import { useCallback, useEffect, useState } from "react"
import { Claim, Dispute, Project } from "services"
import SmartContractService from "services/smart-contract-service"
import { toast } from "sonner"
import { ClaimType } from "types/claims"
import { DisputeStatusEnum, DisputeType } from "types/dispute"
import { ProjectType } from "types/project"
import { getRevertReason } from "utils"

type Props = {
  onClose?: () => void
  project: ProjectType
  account: string
}

const CloseAction = ({ onClose, project, account }: Props) => {
  const { isLoading, startLoading, stopLoading } = useLoading()

  const {
    isLoading: isFetchEntitiesLoading,
    startLoading: startFetchEntities,
    stopLoading: stopFetchEntities,
  } = useLoading()

  const { settings, loading } = useSettings(account)

  const [claims, setClaims] = useState<ClaimType[]>([])
  const [disputes, setDisputes] = useState<DisputeType[]>([])

  const closeProjectHandler = useCallback(async () => {
    const hasOpenDispute = disputes
      .map((d) => Number(d.status))
      .includes(DisputeStatusEnum.Waiting)

    const hasOpenClaim =
      claims.filter((claim) => !!claim.disapproved || !!claim.approved)
        .length === 0

    if (hasOpenDispute)
      return toast.error(t("sections:can_not_close_for_open_dispute"))

    if (hasOpenClaim) {
      return toast.error(t("sections:can_not_close_for_open_claim"))
    }
    const smartContractService = new SmartContractService(
      account,
      MAIN_CONTRACT_ADDRESS
    )
    const p = new Project(smartContractService, project)
    try {
      startLoading()
      await p.closeProject()
      if (onClose) onClose()
      stopLoading()
    } catch (error) {
      getRevertReason(error)
      stopLoading()
    }
  }, [account, onClose, project, startLoading, stopLoading])

  useEffect(() => {
    const getRelatedEntities = async () => {
      const smartContractService = new SmartContractService(
        account,
        READ_CONTRACT_ADDRESS
      )

      const claimService = new Claim(smartContractService)
      const disputeService = new Dispute(smartContractService)

      try {
        startFetchEntities()
        const claimsRes = await claimService.getClaimers(Number(project.id))
        const disputesRes = await disputeService.getDisputesByProjectId(
          Number(project.id)
        )
        stopFetchEntities()
        setDisputes(disputesRes)
        setClaims(claimsRes)
      } catch (error) {
        stopFetchEntities()
      }
    }

    getRelatedEntities()
  }, [])

  if (
    !!!project ||
    isFetchEntitiesLoading ||
    loading ||
    settings === undefined
  ) {
    return (
      <Stack width={"100%"} alignItems={"center"} spacing={2}>
        <Skeleton
          variant="rounded"
          sx={{ width: 600, height: 26, maxWidth: "90%" }}
        />
        <Skeleton
          variant="rounded"
          sx={{ width: 150, height: 36, maxWidth: "90%" }}
        />
      </Stack>
    )
  }

  return (
    <Stack width={"100%"} spacing={2} alignItems={"center"}>
      <Typography variant="bodyMedium">
        {t("sections:expiration_date_is_over")}
      </Typography>
      <LoadingButton
        onClick={closeProjectHandler}
        loading={isLoading}
        variant="contained"
        color="error"
      >
        {t("close_project")}
      </LoadingButton>
    </Stack>
  )
}

export default CloseAction
