import web3 from "web3"

export default function fromWeiToString(wei: any) {
  return "" + Number(wei) / 10 ** 18
}

export function convertToWei(number) {
  // Convert the number to Wei (1 ether = 10^18 Wei)
  const wei = web3.utils.toWei(number?.toString(), "ether")

  return wei
}
