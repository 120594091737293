import { Divider, Paper, Stack, Typography } from "@mui/material"
import ActivityCardInformation from "./information"
import { ProjectType } from "types/project"

interface IActivityCardProps {
  item: ProjectType
  actionNode: React.ReactNode
  statusNode?: React.ReactNode
}

const ActivityCard: React.FunctionComponent<IActivityCardProps> = ({
  item,
  actionNode,
  statusNode,
}) => {
  return (
    <Paper
      sx={{
        p: 2,
        height: "100%",
        minHeight: "250px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Stack sx={{ height: "100%" }} justifyContent={"space-between"}>
        <Stack direction={"column"} spacing={0.5}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              variant="bodyRegular"
              color="text.primary"
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "240px",
              }}
            >
              {item?.title}
            </Typography>
            {statusNode}
          </Stack>
          <Typography
            variant="smallRegular"
            color="text.disabled"
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {item?.documents}
          </Typography>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <ActivityCardInformation item={item} />
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        mt={4}
      >
        {actionNode}
      </Stack>
    </Paper>
  )
}

export default ActivityCard
