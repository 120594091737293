import { StepConnector, stepConnectorClasses, alpha } from "@mui/material"
import { styled } from "@mui/material/styles"

const StepConnectorWrapper = styled((props) => <StepConnector {...props} />)<{
  loading?: boolean
}>(({ theme, loading }) => {
  return {
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 20,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      borderRadius: 1,
      backgroundColor: loading
        ? theme.palette.extended_colors.status.main
        : theme.palette.grey[700],
    },
  }
})

const CustomStepConnector = ({ loading = false }: { loading?: boolean }) => {
  return <StepConnectorWrapper loading={loading} />
}

export default CustomStepConnector
