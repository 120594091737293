import "./App.css"
import "./i18n/i18n"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "extends/extendedPrototypes"

import { MetaMaskProvider } from "@metamask/sdk-react"
import { Web3ReactProvider } from "@web3-react/core"

import { CssBaseline, ThemeProvider, Typography } from "@mui/material"

import GContext from "./contexts/g-context"
import { RouterConfig } from "./navigation"
import { createMyTheme } from "./mui"
import { connectors } from "./connectors"
import ErrorBoundry from "./ErrorBoundry"
import ReduxWrapper from "./store/ReduxWrapper"

import { Toaster } from "sonner"
import { ToastContainer } from "components/shared/Toaster"

function App() {
  return (
    <>
      <Toaster />
      <ErrorBoundry fallback={<Typography>Something went wrong!</Typography>}>
        <Web3ReactProvider connectors={connectors}>
          <MetaMaskProvider
            sdkOptions={{
              dappMetadata: {
                name: "Smart contract",
                url: window?.location?.host ?? "#",
              },
              checkInstallationImmediately: false,
              checkInstallationOnAllCalls: false,
            }}
          >
            <ReduxWrapper>
              <ThemeProvider theme={createMyTheme()}>
                <CssBaseline />
                <ToastContainer />
                <GContext>
                  <RouterConfig />
                </GContext>
              </ThemeProvider>
            </ReduxWrapper>
          </MetaMaskProvider>
        </Web3ReactProvider>
      </ErrorBoundry>
    </>
  )
}

export default App
