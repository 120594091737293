import { Box, Divider, Grid, Paper, Skeleton, Stack } from "@mui/material"

interface Props {}

const ActivitiesListSkeleton = (props: Props) => {
  return (
    <Box>
      <Grid container spacing={2}>
        {[...Array.from(Array(6)).keys()].map((item) => {
          return (
            <Grid item xs={12} md={6} lg={4} key={item + 1}>
              <Paper
                sx={{
                  p: 2,
                  height: "100%",
                  minHeight: "250px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Stack direction={"column"} spacing={0.5}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Skeleton sx={{ width: 200, height: 28 }} variant="text" />
                    <Skeleton
                      sx={{ width: 80, height: 30 }}
                      variant="rounded"
                    />
                  </Stack>
                  <Skeleton sx={{ width: "100%", height: 28 }} variant="text" />
                </Stack>
                <Divider sx={{ my: 1 }} />
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  {[...Array.from(Array(2)).keys()].map((item) => {
                    return (
                      <Skeleton
                        key={item + 1}
                        sx={{ width: "100%", height: 98 }}
                        variant="rounded"
                      />
                    )
                  })}
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  mt={4}
                >
                  <Skeleton sx={{ width: 150, height: 36 }} variant="rounded" />
                </Stack>
              </Paper>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default ActivitiesListSkeleton
