import * as React from "react"

export interface IconType {
  color?: string
}

interface ICloseIconProps extends IconType {
  size?: number
}

const CloseIcon: React.FunctionComponent<ICloseIconProps> = ({
  color = "#8F8DE0",
  size,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 -960 960 960"
      width={size}
    >
      <path
        fill={color}
        d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
      />
    </svg>
  )
}

export default CloseIcon
