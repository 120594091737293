import { useCallback, useEffect, useState } from "react"
import { LinkIcon } from "components/icons"
import {
  SimpleUploader,
  SmallFileViewer,
  TitleValueViewer,
} from "components/shared"
import { AttachmentType } from "types/attachment"
import { Stack } from "@mui/material"
import { t } from "i18next"

type Props = {
  defaultItems?: AttachmentType[]
  onSelect?: (items: (AttachmentType | File)[]) => void
}

const GalleryHandler = ({ defaultItems, onSelect }: Props) => {
  const [files, setFiles] = useState<File[] | AttachmentType[]>([])

  const selectFileHandler = useCallback(
    (file) => {
      setFiles((crt) => {
        let nItems = [...crt, file]
        if (onSelect) onSelect(nItems)
        return nItems
      })
    },
    [onSelect]
  )

  const removeFileHandler = useCallback(
    (file: File | AttachmentType) => {
      setFiles((crt) => {
        let nItems = crt.filter((crtFiles) => crtFiles.name !== file.name)
        if (onSelect) onSelect(nItems)
        return nItems
      })
    },
    [onSelect]
  )

  useEffect(() => {
    if (defaultItems !== undefined) setFiles(defaultItems)
  }, [defaultItems])

  let valueContent = (
    <Stack
      direction={"row"}
      sx={{
        width: "100%",
        flexWrap: "wrap",
        "& > *": { m: 1 },
        "&>:first-child": { ml: 0 },
      }}
    >
      <SimpleUploader onUploadFile={selectFileHandler} />
      {files.map((file, index) => {
        return (
          <SmallFileViewer
            onRemove={() => removeFileHandler(file)}
            key={index + 1}
            file={file}
          />
        )
      })}
    </Stack>
  )

  return (
    <TitleValueViewer
      direction="column"
      title={t("attachments")}
      icon={<LinkIcon />}
      value={valueContent}
    />
  )
}

export default GalleryHandler
