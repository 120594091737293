import { Stack, SxProps, Theme, Typography } from "@mui/material"
import React, { ReactNode } from "react"

type Props = {
  icon: ReactNode
  title: string
  afterNode?: ReactNode
  spacing?: number
  sxProps?: SxProps<Theme>
}

const IconWithTitle = ({
  title,
  spacing = 2,
  icon,
  afterNode,
  sxProps,
}: Props) => {
  return (
    <Stack alignItems={"center"} spacing={spacing} width={"100%"} sx={sxProps}>
      <Stack alignItems={"center"} spacing={1} width={"100%"}>
        {icon}
        <Typography
          lineHeight={1.7}
          className="title"
          variant="bodyMedium"
          textAlign={"center"}
        >
          {title}
        </Typography>
      </Stack>
      {afterNode && afterNode}
    </Stack>
  )
}

export default IconWithTitle
