import { convertToWei } from "utils/fromWeiToString"

class SmartContractWrite {
  private account: string
  private smartContract: any
  private methodName: string
  private args: any[] = []
  private value: string | number

  constructor(account, smartContract: any) {
    this.account = account
    this.smartContract = smartContract
  }

  public setMethodName(name: string) {
    this.methodName = name
    return this
  }

  public setValue(value: any, hasConvertToWei: boolean = true) {
    this.value = hasConvertToWei ? convertToWei(value) : value
    return this
  }

  public setArgs(args: any[]) {
    this.args = args
    return this
  }

  public async do() {
    const account = this.account

    let payload: { [key: string]: any } = {
      from: account,
    }

    let argsToSend = { ...this.args }

    if (this.value) payload["value"] = this.value

    const res = this.smartContract?._methods[this.methodName]?.(
      ...Object.values(argsToSend)
    ).send(payload)

    return res
  }
}

export default SmartContractWrite
