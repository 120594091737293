//Built-in import
import * as React from "react"
//External import
import { Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
//Internal import
import EthereumIcon from "components/icons/ethereum-icon"
import MonitorIcon from "components/icons/monitor-icon"
import TimerIcon from "components/icons/timer-icon"
import { ProjectType } from "types/project"
import { fromWeiToString, humanDate } from "utils"
import { ActivityInfoBox } from "components/shared"

interface IContractCardInformationProps {
  item: ProjectType
}

const ContractCardInformation: React.FunctionComponent<
  IContractCardInformationProps
> = ({ item }) => {
  const { t } = useTranslation()
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      {/* <ActivityInfoBox
        title={t("AD_type")}
        icon={<MonitorIcon />}
        value={"-"}
      /> */}
      <ActivityInfoBox
        icon={<EthereumIcon />}
        title={t("reward")}
        value={fromWeiToString(item.reward)}
      />
      <ActivityInfoBox
        icon={<TimerIcon />}
        title={t("due_date")}
        value={humanDate(Number(item.duedate) * 1000, "YYYY/MM/DD HH:mm", "en")}
      />
    </Stack>
  )
}

export default ContractCardInformation
