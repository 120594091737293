import { MAIN_CONTRACT_ADDRESS } from "consistants"
import { useEffect, useRef, useState } from "react"
import { Project } from "services"
import SmartContractService from "services/smart-contract-service"
import { ClaimType } from "types/claims"
import { DisputeType } from "types/dispute"
import { ProjectType } from "types/project"
import { getRevertReason } from "utils"

export enum AccountTypeEnum {
  Owner = "owner",
  Contractor = "contractor",
  Jury = "jury",
}

const generateOwnerClaims = (
  projects: ProjectType[],
  claimers: ClaimType[],
  disputers: DisputeType[],
  account: string,
  type: AccountTypeEnum
) => {
  if (!!!projects) return []
  const isOwner = type === AccountTypeEnum.Owner
  let claims = []
  for (let project of projects) {
    const contractors = project.contractors
    const hasClaimers = claimers.length > 0

    let isProjectMine: boolean = false
    for (let item of contractors) {
      if (item?.toLowerCase() === account?.toLowerCase()) isProjectMine = true
    }
    if (hasClaimers && isOwner) {
      if (
        project.owner?.toLowerCase() === account?.toLowerCase() &&
        claimers.map((c) => Number(c.projectId)).includes(Number(project.id))
      ) {
        claims.push(project)
      }
    } else {
      if (hasClaimers && isProjectMine) {
        claims.push(project)
      }
    }
  }

  claims = claims
    .map((project) => {
      const disputes = []
      for (let dispute of disputers) {
        if (Number(project.id) === Number(dispute.projectId)) {
          disputes.push(dispute)
        }
      }
      const finalClaims = []
      for (let claim of claimers) {
        if (Number(project.id) === Number(claim.projectId)) {
          finalClaims.push(claim)
        }
      }
      return { ...project, claimers: finalClaims, disputers: disputes }
    })
    .flatMap((obj) => {
      return obj.claimers.reverse().map((item: ClaimType) => ({
        ...item,
        disputers: obj.disputers,
        owner: obj?.owner,
        description: obj?.documents,
        title: obj?.title,
      }))
    })

  if (isOwner) {
    return claims
  } else {
    return claims.filter(
      (claim: ClaimType) =>
        claim.contractor?.toLowerCase() === account?.toLowerCase()
    )
  }
}

const useProjectsAsClaims = (
  type: AccountTypeEnum,
  account: string,
  defaultClaims: ClaimType[],
  defaultDisputes: DisputeType[]
): { claims: ProjectType[]; loading: boolean; isCached: boolean } => {
  const isCached = useRef(false)
  const [loading, setLoading] = useState(false)
  const [projects, setProjects] = useState<ProjectType[] | undefined>(undefined)

  useEffect(() => {
    const getProjects = async () => {
      const smartContractService = new SmartContractService(
        account,
        MAIN_CONTRACT_ADDRESS
      )
      const project = new Project(smartContractService)
      try {
        setLoading(true)
        const res = await project.getAllProjects()
        setProjects(res.reverse() ?? [])
        setTimeout(() => {
          setLoading(false)
        }, 500)
      } catch (error) {
        setLoading(false)
        getRevertReason(error)
      }
    }
    getProjects()
  }, [account])

  let separatedProjects = []
  if (projects && type === AccountTypeEnum.Owner) {
    separatedProjects = [
      ...generateOwnerClaims(
        projects,
        defaultClaims,
        defaultDisputes,
        account,
        AccountTypeEnum.Owner
      ),
    ]
    isCached.current = true
  }
  if (projects && type === AccountTypeEnum.Contractor) {
    separatedProjects = [
      ...generateOwnerClaims(
        projects,
        defaultClaims,
        defaultDisputes,
        account,
        AccountTypeEnum.Contractor
      ),
    ]
    isCached.current = true
  }

  return {
    claims: separatedProjects,
    loading: projects === undefined || loading,
    isCached: isCached.current,
  }
}

export default useProjectsAsClaims
