"use client";

import { useValues } from "hooks";
import { ReactNode, useCallback, useEffect, useState } from "react";

type Props<T = any> = {
  defaultStep?: number;
  initValues?: T;
  children: ({
    step,
  }: {
    step: number;
    prevStep: () => void;
    nextStep: () => void;
    values: T | undefined;
    changeKey: (key: string, value: any) => void;
    changeBulk: (values: object) => void;
    changeStep: (step: number) => void;
  }) => ReactNode;
};

export default function StepValues<T>({
  defaultStep,
  children,
  initValues,
}: Props<T>) {
  //Our values
  const { values, changeKey, changeBulk } = useValues(initValues);
  useEffect(() => {
    if (initValues !== undefined) changeBulk(initValues);
  }, [changeBulk, initValues]);

  const [step, setStep] = useState(defaultStep || 0);
  useEffect(() => {
    if (defaultStep !== undefined) setStep(defaultStep);
  }, [defaultStep, step]);

  const nextStep = useCallback(() => setStep((crt) => crt + 1), []);
  const changeStep = useCallback(
    (step: number) => setStep(step > 0 ? step : 0),
    []
  );
  const prevStep = useCallback(() => {
    if (step > 0) return setStep((crt) => crt - 1);
    return 0;
  }, [step]);

  const output = {
    step,
    nextStep,
    prevStep,
    changeStep,
    values,
    changeKey,
    changeBulk,
  };

  return <>{children(output)}</>;
}
