import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  alpha,
} from "@mui/material"
import React, { Fragment } from "react"
import { ScNormalStepper } from ".."

interface Props {}

const paperClss = {
  p: 3,
  height: "100%",
  minHeight: 400,
  display: "flex",
  flexDirection: "column",
  gap: 4,
  width: "100%",
  justifyContent: "flex-start",
}

const DisputePageLoading = (props: Props) => {
  return (
    <Stack
      sx={{
        height: "100vh",
        width: "100%",
      }}
      alignItems={"center"}
      spacing={6}
    >
      <Stack
        direction={{ xs: "column", lg: "row" }}
        width={"100%"}
        justifyContent={"space-between"}
        alignItems="center"
        minHeight={72}
        bgcolor={(t) => alpha(t.palette.secondary.dark, 0.5)}
        px={4}
        py={1}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          {[...Array.from(Array(3)).keys()].map((i) => {
            return (
              <Fragment key={i + 1}>
                <Skeleton sx={{ width: 75, height: 24 }} variant="rounded" />
                {i < 2 && <span style={{ marginLeft: 8 }}>/</span>}
              </Fragment>
            )
          })}
        </Stack>
        <Stack direction={"row"} alignItems={"flex-end"} spacing={2}>
          <Skeleton sx={{ width: 185, height: 32 }} variant="rounded" />
          <Skeleton sx={{ width: 150, height: 32 }} variant="rounded" />
          <Skeleton sx={{ width: 83, height: 21 }} variant="rounded" />
        </Stack>
      </Stack>
      <Container maxWidth={"lg"}>
        <Box sx={{ pb: 4 }}>
          <Grid container spacing={2}>
            <Grid
              container
              item
              spacing={1}
              xs={12}
              xl={9.5}
              sx={{
                height: "100%",
                overflowY: "auto",
                order: [2, , , , "initial"],
              }}
            >
              <Grid item xs={12}>
                <Paper>
                  <ScNormalStepper
                    loading
                    activeStep={0}
                    steps={[...Array.from(Array(5))]}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper sx={paperClss}>
                  <Stack width={"100%"}>
                    {[...Array.from(Array(6)).keys()].map((i) => {
                      return (
                        <Fragment key={i + 1}>
                          <Stack
                            direction={"row"}
                            width={"100%"}
                            py={1.5}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Skeleton
                              sx={{ width: 100, height: 30 }}
                              variant="rounded"
                            />
                            <Skeleton
                              sx={{ width: 100, height: 30 }}
                              variant="rounded"
                            />
                          </Stack>
                          {i < 5 && <Divider />}
                        </Fragment>
                      )
                    })}
                  </Stack>
                  <Stack width={"100%"} spacing={3}>
                    {[...Array.from(Array(3)).keys()].map((i) => {
                      return (
                        <Stack
                          key={i + 1}
                          direction={"row"}
                          width={"100%"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"end"}
                            justifyContent={"space-between"}
                            width={"100%"}
                          >
                            <Stack spacing={0.5}>
                              <Skeleton
                                sx={{ width: 100, height: 21 }}
                                variant="text"
                              />
                              <Skeleton
                                sx={{ height: 24, width: 300 }}
                                variant="text"
                              />
                            </Stack>
                            <Skeleton
                              sx={{ width: 64, height: 36 }}
                              variant="rounded"
                            />
                          </Stack>
                        </Stack>
                      )
                    })}
                  </Stack>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper sx={paperClss}>
                  <Stack
                    sx={{ width: "100%" }}
                    spacing={1.5}
                    alignItems={"start"}
                  >
                    <Skeleton sx={{ width: 150, height: 26 }} variant="text" />
                    <Stack
                      direction={"row"}
                      spacing={2}
                      alignItems={"center"}
                      flexWrap={"wrap"}
                      width={"100%"}
                    >
                      {[...Array.from(Array(4)).keys()].map((i) => {
                        return (
                          <Skeleton
                            key={i + 1}
                            sx={{ width: 90, height: 90 }}
                            variant="rounded"
                          />
                        )
                      })}
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12} xl={2.5}>
              <Paper sx={{ height: "100%", p: 3, minHeight: 500 }}>
                <Stack
                  width={"100%"}
                  height={"100%"}
                  spacing={6}
                  justifyContent={"space-between"}
                >
                  <Stack alignItems={"center"} spacing={1}>
                    <Skeleton
                      sx={{ width: 82, height: 30 }}
                      variant="rounded"
                    />
                    <Skeleton
                      sx={{ width: 252, height: 21 }}
                      variant="rounded"
                    />
                    <Skeleton
                      sx={{ width: 200, height: 18 }}
                      variant="rounded"
                    />
                    <Skeleton
                      sx={{ width: 200, height: 18 }}
                      variant="rounded"
                    />

                    <Skeleton
                      sx={{ width: 200, height: 18 }}
                      variant="rounded"
                    />
                    <Skeleton
                      sx={{ width: 200, height: 18 }}
                      variant="rounded"
                    />
                  </Stack>

                  <Stack width={"100%"} spacing={2}>
                    <Stack spacing={2}>
                      <Skeleton
                        sx={{ width: 246, height: 60 }}
                        variant="rounded"
                      />
                      <Skeleton
                        sx={{ width: 246, height: 60 }}
                        variant="rounded"
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Stack>
  )
}
export default DisputePageLoading
