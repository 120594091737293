import { useSDK } from "@metamask/sdk-react"
import { useSettings } from "hooks"
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react"

//define enum for any step
export enum ETimelineSteps {
  "Created" = 0,
  "Active" = 1,
  "Dispute" = 2,
  "Finalize" = 3,
  "Closing" = 4,
}

//define type for our context
type TimelineCtxType = {
  step: ETimelineSteps
  timeout: number
  loading?: boolean
  changeToDisputeHandler: () => void
  changeToFinalizeHanlder: () => void
  changeToFinishHandler: () => void
}

//initial defination of context
const initContext: TimelineCtxType = {
  step: 0,
  loading: false,
  timeout: new Date().getTime(),
  changeToDisputeHandler: () => {},
  changeToFinalizeHanlder: () => {},
  changeToFinishHandler: () => {},
}

//create context
const TimelineContext = createContext(initContext)

//define type for our state that should be changed
type InitStateType = {
  step: ETimelineSteps
  timeout: number
}
//define initial state base on init state type
const initialState: InitStateType = {
  step: ETimelineSteps.Created,
  timeout: 0,
}

type Props = {
  defaultTime?: number
  children: ReactNode
}

type ActivateProjectType = {
  type: "ACTIVATE_PROJECT_ACTION"
  payload: number
}

type FinalizeProjectType = {
  type: "FINALIZE_DISPUTE_DELAY"
  payload: number
}
type DisputeProjectType = {
  type: "DISPUTE_PROJECT_DELAY"
  payload: number
}

type ClosingProjectType = {
  type: "CLOSING_PROJECT_DELAY"
  payload: number
}

type TimelineActionType =
  | FinalizeProjectType
  | ClosingProjectType
  | DisputeProjectType
  | ActivateProjectType

const timelineReducer = (state: InitStateType, action: TimelineActionType) => {
  switch (action.type) {
    case "ACTIVATE_PROJECT_ACTION":
      return { step: ETimelineSteps.Active, timeout: action.payload }
    case "DISPUTE_PROJECT_DELAY":
      return {
        step: ETimelineSteps.Dispute,
        timeout: state.timeout + action.payload,
      }
    case "FINALIZE_DISPUTE_DELAY":
      return {
        step: ETimelineSteps.Finalize,
        timeout: state.timeout + action.payload,
      }
    case "CLOSING_PROJECT_DELAY":
      return {
        step: ETimelineSteps.Closing,
        timeout: state.timeout + action.payload,
      }
    default:
      return state
  }
}

const TimelineContextProvider = ({ defaultTime, children }: Props) => {
  const { account } = useSDK()
  const { settings, loading } = useSettings(account)

  //get dispute only dely in milliseconds
  let disputeOnlyDely = Number(settings?.projectDisputeOnlyPeriod ?? 0) * 1000

  //get project finalize dely in milliseconds
  let projectFinalizeDelay = Number(settings?.projectFinalizePeriod ?? 0) * 1000

  //get project closing dely in milliseconds
  let projectClosingDelay = Number(settings?.projectClosingPeriod ?? 0) * 1000

  const isLoading =
    !!loading ||
    settings === undefined ||
    !disputeOnlyDely ||
    !projectFinalizeDelay ||
    !projectClosingDelay

  const [state, dispatch] = useReducer(timelineReducer, initialState)

  //change from dispute step to finalize timeout
  const changeToDisputeHandler = () => {
    dispatch({ type: "DISPUTE_PROJECT_DELAY", payload: disputeOnlyDely })
  }

  //change step when finalize time got end
  const changeToFinalizeHanlder = () => {
    dispatch({
      type: "FINALIZE_DISPUTE_DELAY",
      payload: projectFinalizeDelay,
    })
  }

  //change step when closing time got end

  const changeToFinishHandler = () => {
    dispatch({
      type: "CLOSING_PROJECT_DELAY",
      payload: projectClosingDelay,
    })
  }

  useEffect(() => {
    if (defaultTime !== undefined && !isLoading) {
      const now = new Date().getTime()

      const DISPUTE_TIME = defaultTime + disputeOnlyDely
      const FINALIZE_TIME = DISPUTE_TIME + projectFinalizeDelay
      const CLOSING_TIME = FINALIZE_TIME + projectClosingDelay

      const projectActivationDeps =
        DISPUTE_TIME - now > 0 && FINALIZE_TIME - now > 0

      if (!isLoading) {
        if (defaultTime - now <= 0 && projectActivationDeps) {
          dispatch({
            type: "DISPUTE_PROJECT_DELAY",
            payload: DISPUTE_TIME,
          })
        } else if (DISPUTE_TIME - now <= 0 && FINALIZE_TIME - now >= 0) {
          dispatch({
            type: "FINALIZE_DISPUTE_DELAY",
            payload: FINALIZE_TIME,
          })
        } else if (FINALIZE_TIME - now <= 0) {
          dispatch({
            type: "CLOSING_PROJECT_DELAY",
            payload: 0,
          })
        } else {
          dispatch({
            type: "ACTIVATE_PROJECT_ACTION",
            payload: defaultTime,
          })
        }
      }
    }
  }, [
    defaultTime,

    disputeOnlyDely,
    isLoading,
    projectClosingDelay,
    projectFinalizeDelay,
  ])

  return (
    <TimelineContext.Provider
      value={{
        step: state.step,
        timeout: state.timeout,
        loading: isLoading,
        changeToDisputeHandler,
        changeToFinalizeHanlder,
        changeToFinishHandler,
      }}
    >
      {children}
    </TimelineContext.Provider>
  )
}

export const useTimelineContext = () => useContext(TimelineContext)

export default TimelineContextProvider
