import { Button } from "@mui/material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import copy from "copy-text-to-clipboard"
import { t } from "i18next"
import { useState } from "react"

type Props = {
  text: string
  variant?: "text" | "outlined"
  onCopied?: (text: string) => void
  isHashText?: boolean
}

export default function CopyText({
  text,
  onCopied,
  isHashText,
  variant = "outlined",
}: Props) {
  const [loading, setLoading] = useState(false)
  function copieClickHandler() {
    setLoading(true)
    copy(text)
    if (onCopied) onCopied(text)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  let targetTextToShow = text

  if (!!!targetTextToShow) return null

  if (isHashText) {
    targetTextToShow = `${targetTextToShow.substring(
      0,
      6
    )}****${targetTextToShow?.substring(targetTextToShow.length - 4)}`
  }

  return (
    <Button
      variant={variant}
      onClick={copieClickHandler}
      startIcon={<ContentCopyIcon sx={{ width: 14, height: 14 }} />}
    >
      {!loading ? targetTextToShow : t("copied")}
    </Button>
  )
}
