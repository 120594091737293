import { Stack, SxProps, Theme } from "@mui/material"
import { ReactNode } from "react"

interface Props {
  children: ReactNode
  paginateNode: ReactNode
  beforeNode?: ReactNode
  afterNode?: ReactNode
}

const FilterHolder = ({
  children,
  className,
  sx = {},
}: {
  children: ReactNode
  className: string
  sx?: SxProps<Theme>
}) => {
  return (
    <Stack direction={"row"} className={className} sx={sx} spacing={1}>
      {children}
    </Stack>
  )
}

const ListingHolder = ({
  children,
  afterNode,
  beforeNode,
  paginateNode,
}: Props) => {
  let sharedStyle: SxProps<Theme> = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    position: "relative",
  }
  if (!beforeNode && !!afterNode) {
    sharedStyle = {
      ...sharedStyle,
      py: 4,
      "& .after-node": {
        position: "absolute !important",
        right: 0,
        justifyContent: "end",
      },
    }
  }
  let actionNode = null

  if (!!beforeNode || !!afterNode) {
    actionNode = (
      <Stack direction={"row"} sx={sharedStyle} spacing={1}>
        {!!beforeNode && (
          <FilterHolder sx={sharedStyle} className="before-node">
            {beforeNode}
          </FilterHolder>
        )}
        {!!afterNode && (
          <FilterHolder sx={{ ...sharedStyle, flex: 1 }} className="after-node">
            {afterNode}
          </FilterHolder>
        )}
      </Stack>
    )
  }

  return (
    <Stack spacing={3} width={"100%"} height={"100%"} alignItems={"center"}>
      {/**filter node should here  */}
      {actionNode}
      {/**filter node should here  */}
      {children}
      {paginateNode}
    </Stack>
  )
}
export default ListingHolder
