import {
  CircularProgress,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material"

type LoadingProps = {
  sxProps?: SxProps<Theme>
}

export default function Loading({ sxProps = {} }: LoadingProps) {
  return (
    <Stack spacing={2} alignItems={"center"} sx={sxProps}>
      <CircularProgress />
      <Typography>Loading ... </Typography>
    </Stack>
  )
}
