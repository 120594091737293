import React from "react";

import { Loading } from "components/shared";

type Props = {
  element: React.ReactNode;
  permission?: string;
};

/**
 * @param {React.ReactNode} element the component to be lazy loaded
 * @example `<Route path="path" element={<LazyRoute element={<HomePage />} />} />`
 */

const LazyRoute: React.FC<Props> = ({ element, permission }) => {
  return (
    <React.Suspense
      fallback={
        <Loading
          sxProps={{
            height: "100vh",
            m: "auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        /> /* will add fallback later */
      }
    >
      {element}
    </React.Suspense>
  );
};

export default LazyRoute;
