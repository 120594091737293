import { useSDK } from "@metamask/sdk-react"
import { DisputeStatusEnum, DisputeType } from "types/dispute"

import { useSettings } from "hooks"
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react"

//define enum for any step
export enum EDisputeTimelineStep {
  "Created" = 0,
  "Voting" = 1,
  "Revealing" = 2,
  "Finalize" = 3,
  "Finish" = 4,
}

export enum EBallotType {
  "Contractor" = 1,
  "Employer" = 0,
}

type DisputeContextType = {
  timeout: number
  loading: boolean
  dispute: DisputeType
  step: EDisputeTimelineStep
  isVoteCasted: boolean
  isVoteRevealed: boolean
  isDisputeFinalized: boolean
  vote: number
  ballot: number[]
  setVoteCasted: () => void
  setVoteRevealed: () => void
  increaseVote: (vote: number) => void
  changeToRevealStepHandler: () => void
  changeToFinalizeStepHandler: () => void
  changeToFinishStepHandler: () => void
  changeBallotHandler: () => void
  changeStateByKey: (key: string, value: any) => void
}

type SetVoteCastedType = {
  type: "SET_VOTE_CASTED"
}

type SetVoteRevealedType = {
  type: "SET_VOTE_REVEALED"
}
type ChangeVoteQuantityType = {
  type: "CHANGE_VOTE_QUANTITY"
  payload: number
}
type VotingStepType = {
  type: "VOTING_STEP_ACTION"
  payload: number
}
type RevealVoteStepType = {
  type: "REVEAL_STEP_ACTION"
  payload: number
}
type FinalizeStepType = {
  type: "FINALIZE_STEP_ACTION"
  payload: number
}
type FinishStepType = {
  type: "FINISH_STEP_ACTION"
  payload: number
}
type SetBallotType = {
  type: "SET_BALLOT_ACTION"
  payload: number[]
}

type ChangeBallotType = {
  type: "CHANGE_BALLOT_ACTION"
  payload: EBallotType
}
type SetInitVotesType = {
  type: "SET_INIT_VOTE_TYPE"
  payload: number
}
type SetDisputeFinalizedType = {
  type: "SET_DISPUTE_FINALIZED"
}

type ChangeStateByKeyType = {
  type: "CHANGE_KEY"
  payload: { key: string; value: any }
}

type JudgeActionType =
  | SetVoteCastedType
  | SetVoteRevealedType
  | ChangeVoteQuantityType
  | VotingStepType
  | RevealVoteStepType
  | FinalizeStepType
  | FinishStepType
  | SetBallotType
  | ChangeBallotType
  | SetInitVotesType
  | SetDisputeFinalizedType
  | ChangeStateByKeyType

const initialContext: DisputeContextType = {
  isVoteCasted: false,
  dispute: undefined,
  isVoteRevealed: false,
  isDisputeFinalized: false,
  ballot: [],
  loading: false,
  vote: 0,
  step: EDisputeTimelineStep.Created,
  timeout: new Date().getTime(),
  setVoteCasted: () => {},
  setVoteRevealed: () => {},
  changeBallotHandler: () => {},
  increaseVote: () => {},
  changeToRevealStepHandler: () => {},
  changeToFinalizeStepHandler: () => {},
  changeToFinishStepHandler: () => {},
  changeStateByKey: () => {},
}

type JudgeInitStateType = {
  dispute: DisputeType
  step: EDisputeTimelineStep
  timeout: number
  isVoteCasted: boolean
  isVoteRevealed: boolean
  isDisputeFinalized: boolean
  vote: number
  ballot: number[]
}

const initialState: JudgeInitStateType = {
  dispute: undefined,
  step: EDisputeTimelineStep.Created,
  timeout: 0,
  isVoteCasted: false,
  isVoteRevealed: false,
  isDisputeFinalized: false,
  vote: 0,
  ballot: [],
}

const judgeReducer = (state: JudgeInitStateType, action: JudgeActionType) => {
  switch (action.type) {
    case "CHANGE_KEY": {
      const key = action.payload.key
      const value = action.payload.value
      return { ...state, [key]: value }
    }
    case "SET_BALLOT_ACTION":
      return { ...state, ballot: action.payload }
    case "SET_INIT_VOTE_TYPE":
      return { ...state, vote: action.payload }
    case "CHANGE_BALLOT_ACTION":
      return { ...state, ballot: [...state.ballot, action.payload] }
    case "SET_VOTE_CASTED":
      return { ...state, isVoteCasted: true }
    case "SET_VOTE_REVEALED":
      return { ...state, isVoteRevealed: true }
    case "SET_DISPUTE_FINALIZED":
      return { ...state, isDisputeFinalized: true }
    case "CHANGE_VOTE_QUANTITY":
      return { ...state, vote: action.payload }
    case "VOTING_STEP_ACTION":
      return {
        ...state,
        step: EDisputeTimelineStep.Voting,
        timeout: action.payload,
      }
    case "REVEAL_STEP_ACTION":
      return {
        ...state,
        step: EDisputeTimelineStep.Revealing,
        timeout: state.timeout + action.payload,
      }
    case "FINALIZE_STEP_ACTION":
      return {
        ...state,
        step: EDisputeTimelineStep.Finalize,
        timeout: state.timeout + action.payload,
      }
    case "FINISH_STEP_ACTION":
      return {
        ...state,
        step: EDisputeTimelineStep.Finish,
        timeout: state.timeout + action.payload,
      }
    default:
      return state
  }
}
const DisputeContext = createContext(initialContext)

const DisputeContextProvider = ({
  children,
  dispute,
}: {
  dispute: DisputeType
  children: ReactNode
}) => {
  const { account } = useSDK()
  const [state, dispatch] = useReducer(judgeReducer, initialState)

  const { settings, loading } = useSettings(account)

  //get dispute voting period in milliseconds
  let disputeVotingPeriod = Number(settings?.disputeVotingPeriod ?? 0) * 1000

  //get dispute reveal period in milliseconds
  let disputeRevealingPeriod =
    Number(settings?.disputeRevealingPeriod ?? 0) * 1000

  //get dispute finalize period  in milliseconds
  let disputeFinalizePeriod =
    Number(settings?.disputeFinalizePeriod ?? 0) * 1000

  const isLoading =
    !!loading ||
    settings === undefined ||
    !disputeVotingPeriod ||
    !disputeRevealingPeriod ||
    !disputeFinalizePeriod

  const setCastedHandler = () => {
    dispatch({ type: "SET_VOTE_CASTED" })
  }
  const setRevealedHandler = () => {
    dispatch({ type: "SET_VOTE_REVEALED" })
  }
  const changeVoteHandler = (vote: number) => {
    dispatch({ type: "CHANGE_VOTE_QUANTITY", payload: vote })
  }
  //change from voting step to reveal step
  const changeToRevealStepHandler = () => {
    dispatch({ type: "REVEAL_STEP_ACTION", payload: disputeRevealingPeriod })
  }
  //change from reveal step to finalize step
  const changeToFinalizeStepHandler = () => {
    dispatch({
      type: "FINALIZE_STEP_ACTION",
      payload: disputeFinalizePeriod,
    })
  }
  //change from finalize step to finish step
  const changeToFinishStepHandler = () => {
    dispatch({
      type: "FINISH_STEP_ACTION",
      payload: 0,
    })
  }
  //change from finalize step to finish step
  const changeStateByKey = (key: string, value: any) => {
    dispatch({
      type: "CHANGE_KEY",
      payload: { key, value },
    })
  }

  useEffect(() => {
    if (dispute !== undefined && !isLoading) {
      const now = new Date().getTime()

      const isDisputeFinalized =
        Number(dispute.status) !== DisputeStatusEnum.Waiting
      const disputedTime = Number(dispute.timestamp) * 1000
      const disputeBallot = dispute.ballot ?? []
      const disputeVotes = dispute.voted
      //check this account casted vote on this dispute or not
      const isDisputeCasted = dispute.cast
        .map((c) => c.toLowerCase())
        .includes(account.toLowerCase())
      //check this account revealed vote on this dispute or not
      const isDisputeRevealed = dispute.voted
        .map((c) => c.toLowerCase())
        .includes(account.toLowerCase())

      if (isDisputeCasted) {
        dispatch({ type: "SET_VOTE_CASTED" })
      }
      if (isDisputeRevealed) {
        dispatch({ type: "SET_VOTE_REVEALED" })
      }
      if (isDisputeFinalized) {
        dispatch({ type: "SET_DISPUTE_FINALIZED" })
      }

      dispatch({
        type: "SET_BALLOT_ACTION",
        payload: disputeBallot,
      })
      dispatch({
        type: "SET_INIT_VOTE_TYPE",
        payload: disputeVotes.length,
      })
      dispatch({
        type: "CHANGE_KEY",
        payload: { key: "dispute", value: dispute },
      })
      const VOTING_TIME = disputedTime + disputeVotingPeriod
      const REVEAL_TIME = VOTING_TIME + disputeRevealingPeriod
      const FINALIZE_TIME = REVEAL_TIME + disputeFinalizePeriod

      const VotingActivitionDeps =
        REVEAL_TIME - now > 0 && FINALIZE_TIME - now > 0

      if (!isLoading) {
        if (VOTING_TIME - now <= 0 && VotingActivitionDeps) {
          dispatch({
            type: "REVEAL_STEP_ACTION",
            payload: REVEAL_TIME,
          })
        } else if (REVEAL_TIME - now <= 0 && FINALIZE_TIME - now >= 0) {
          dispatch({
            type: "FINALIZE_STEP_ACTION",
            payload: FINALIZE_TIME,
          })
        } else if (FINALIZE_TIME - now <= 0) {
          dispatch({
            type: "FINISH_STEP_ACTION",
            payload: 0,
          })
        } else {
          dispatch({
            type: "VOTING_STEP_ACTION",
            payload: VOTING_TIME,
          })
        }
      }
    }
  }, [
    account,
    dispute,
    disputeFinalizePeriod,
    disputeRevealingPeriod,
    disputeVotingPeriod,
    isLoading,
  ])

  return (
    <DisputeContext.Provider
      value={{
        dispute: state.dispute,
        changeStateByKey,
        changeBallotHandler: () => {},
        isDisputeFinalized: state.isDisputeFinalized,
        loading: isLoading,
        ballot: state.ballot,
        step: state.step,
        timeout: state.timeout,
        vote: state.vote,
        isVoteCasted: state.isVoteCasted,
        isVoteRevealed: state.isVoteRevealed,
        setVoteCasted: setCastedHandler,
        setVoteRevealed: setRevealedHandler,
        increaseVote: changeVoteHandler,
        changeToRevealStepHandler,
        changeToFinalizeStepHandler,
        changeToFinishStepHandler,
      }}
    >
      {children}
    </DisputeContext.Provider>
  )
}

export default DisputeContextProvider

export const useDisputeContext = () => useContext(DisputeContext)
