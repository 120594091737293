import Web3 from "web3"
import SmartContractAbi from "./smart-contract-abi"
import SmartContractRead from "./smart-contract-read"
import SmartContractWrite from "./smart-contract-write"

const web3 = new Web3((window as any).ethereum as any)

class SmartContractService {
  private contractAddress: string
  private abi: any
  public account: string

  constructor(account, address) {
    this.account = account
    this.contractAddress = address
  }

  private async init() {
    const smartContractAbi = new SmartContractAbi()
    const abi = await smartContractAbi.fetchContract(this.contractAddress)
    this.abi = abi
  }

  async getReadInstance() {
    await this.init()

    const contract = new web3.eth.Contract(this.abi, this.contractAddress)
    const smartContractRead = new SmartContractRead(this.account, contract)

    return smartContractRead
  }

  async getWriteInstance() {
    await this.init()
    const contract = new web3.eth.Contract(this.abi, this.contractAddress)
    const smartContractWrite = new SmartContractWrite(this.account, contract)
    return smartContractWrite
  }
}
export default SmartContractService
