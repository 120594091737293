import fromWeiToString from "utils/fromWeiToString"
import SmartContractService from "./smart-contract-service"
import { DisputeType } from "types/dispute"
import { ProjectType } from "types/project"

class Jury {
  private smartContractService: SmartContractService
  private dispute: DisputeType
  private project: ProjectType

  constructor(
    smartContractService: SmartContractService,
    project?: ProjectType,
    dispute?: DisputeType
  ) {
    this.smartContractService = smartContractService
    if (!!project) this.project = project
    if (!!dispute) this.dispute = dispute
  }

  async isJudge() {
    const account = this.smartContractService.account
    if (!account) return
    const smartContractRead = await this.smartContractService.getReadInstance()
    const res = await smartContractRead
      .setMethodName("isJudge")
      .setArgs([account])
      .do()
    return res
  }
  async registerAsJudge(value: string | number) {
    if (!!!value) return
    const smartContractWrite =
      await this.smartContractService.getWriteInstance()
    const res = await smartContractWrite
      .setMethodName("registerAsJudge")
      .setValue(fromWeiToString(value))
      .do()
    return res
  }
  async getJudges() {
    const smartContractRead = await this.smartContractService.getReadInstance()
    const res = await smartContractRead.setMethodName("getJudges").do()
    return res
  }
  async getJudgeEncodedVote(disputeId: BigInt, projectId: BigInt) {
    const smartContractRead = await this.smartContractService.getReadInstance()
    const account = this.smartContractService.account

    const res = await smartContractRead
      .setMethodName("getJudgeEncodedVote")
      .setArgs([Number(disputeId), account])
      .do()
    return res
  }
  async revealVote(
    number: number,
    nonce: number,
    sign: string,
    nullifier: number,
    mainSign: string
  ) {
    const project = this.project
    const dispute = this.dispute
    if (!!!project || !!!dispute) return

    const smartContractWrite =
      await this.smartContractService.getWriteInstance()
    const res = await smartContractWrite
      .setMethodName("revealVote")
      .setArgs([
        number,
        nonce,
        project.id,
        dispute.id,
        sign,
        nullifier,
        mainSign,
      ])
      .do()
    return res
  }
}

export default Jury
