import { convertToWei } from "utils/fromWeiToString"
import SmartContractService from "./smart-contract-service"

class Wallet {
  private smartContract: SmartContractService
  private account: string

  constructor(smartContractService: SmartContractService, account: string) {
    this.account = account
    this.smartContract = smartContractService
  }

  async getWithdrawableAmount() {
    const smartContractRead = await this.smartContract.getReadInstance()
    const res = await smartContractRead
      .setMethodName("addressToWithdrawableAmounts")
      .setArgs([this.account])
      .do()
    return res
  }

  async getBlockedAmounts() {
    const smartContractRead = await this.smartContract.getReadInstance()
    const res = await smartContractRead
      .setMethodName("blockedAmounts")
      .setArgs([this.account])
      .do()
    return res
  }

  async submitWithdraw(amount: number) {
    const smartContractWrite = await this.smartContract.getWriteInstance()
    const value = 0
    const res = await smartContractWrite
      .setMethodName("withdraw")
      .setArgs([convertToWei(amount)])
      .setValue(value)
      .do()
    return res
  }
}

export default Wallet
