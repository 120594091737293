import { Stack } from "@mui/material"
import StepperWithTimeout from "components/shared/StepperWithTimeout"
import OutlinedFlagTwoToneIcon from "@mui/icons-material/OutlinedFlagTwoTone"
import HowToVoteOutlinedIcon from "@mui/icons-material/HowToVoteOutlined"
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined"
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom"
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined"

import {
  EDisputeTimelineStep,
  useDisputeContext,
} from "contexts/disputeContext"
import { useCallback } from "react"

interface Props {}

const DisputeTimeline = (props: Props) => {
  const {
    step,
    changeToRevealStepHandler,
    changeToFinishStepHandler,
    changeToFinalizeStepHandler,
    loading,
    timeout,
  } = useDisputeContext()

  const changeTimelineStepHandler = useCallback(() => {
    if (step === EDisputeTimelineStep.Voting) {
      changeToRevealStepHandler()
    } else if (step === EDisputeTimelineStep.Revealing) {
      changeToFinalizeStepHandler()
    } else if (step === EDisputeTimelineStep.Finalize) {
      changeToFinishStepHandler()
    } else {
      return
    }
  }, [
    changeToFinalizeStepHandler,
    changeToFinishStepHandler,
    changeToRevealStepHandler,
    step,
  ])

  return (
    <Stack width={"100%"} spacing={4} alignItems={"center"} py={2}>
      <StepperWithTimeout
        onEnd={changeTimelineStepHandler}
        loading={loading}
        timeout={timeout}
        activeStep={step}
        hasTooltip
        steps={[
          {
            label: "Dispute registered",
            icon: <OutlinedFlagTwoToneIcon />,
            description: "Dispute just has been registered by contractor",
          },
          {
            label: "Voting Time",
            icon: <HowToVoteOutlinedIcon />,
            description: "During this period, the judges can submit their vote",
          },
          {
            label: "Revealing Time",
            icon: <VerifiedUserOutlinedIcon />,
            description: "During this period, the judges can issue their vote",
          },
          {
            label: "Finalize Dispute",
            icon: <GavelOutlinedIcon />,
            description:
              "At this step, it is only possible to finalize the dispute request after the end of the voting time",
          },
          {
            label: "Finish",
            icon: <HourglassBottomIcon />,
            description:
              "The voting time and also the registration of the claim or dispute has ended at this stage and only the employer can close her/his project.",
          },
        ]}
      />
    </Stack>
  )
}
export default DisputeTimeline
