import { Stack, Typography } from "@mui/material"
import { useTimer } from "hooks"
import { ReactNode, useEffect, useRef, useState } from "react"
import CountdownViewer from "./CountdownViewer"
import { getTimeformat } from "utils"
import { t } from "i18next"

type Props = {
  targetTime: number
  showCountdown?: boolean
  fullSize?: boolean
  onEnd?: () => void
  children: ({
    seconds,
    isDisabled,
    time,
  }: {
    seconds: number
    time: string
    isDisabled: boolean
  }) => ReactNode
  countdownDesc?: string
  timeOverDesc?: string
}

const CalcRemainedTime = ({
  targetTime,
  showCountdown = true,
  children,
  onEnd,
  fullSize = true,
  countdownDesc = t("sections:countdown_is_started"),
  timeOverDesc = t("sections:time_is_over"),
}: Props) => {
  const timerRef = useRef(true)
  const [localTime, setLocalTime] = useState(0)

  useEffect(() => {
    if (!!targetTime) {
      setLocalTime(targetTime)
      if (!timerRef.current) {
        timerRef.current = true
      }
    }
  }, [targetTime])

  //current time in milliseconds
  const now = new Date().getTime()

  const diff = localTime - now

  const { startTimer, seconds, isDisabled } = useTimer(diff)

  useEffect(() => {
    if (timerRef.current) {
      if (localTime && diff > 0) {
        startTimer()
        timerRef.current = false
      }
    }
  }, [localTime])

  useEffect(() => {
    if (timerRef.current === false && !isDisabled) {
      if (onEnd) onEnd()
    }
  }, [isDisabled, onEnd])

  const time = getTimeformat(seconds, true, true).labeledTime

  let countdownView = (
    <CountdownViewer time={time} description={countdownDesc} />
  )

  if (diff < 0)
    countdownView = <Typography variant="bodyMedium">{timeOverDesc}</Typography>

  // if (!localTime) return null
  return (
    <Stack width={fullSize ? "100%" : "auto"} spacing={2} alignItems={"center"}>
      {showCountdown && countdownView}
      <>
        {children({
          seconds: Math.floor(seconds),
          time,
          isDisabled: isDisabled || diff >= 0,
        })}
      </>
    </Stack>
  )
}

export default CalcRemainedTime
