import { Navigate } from "react-router-dom"

import React from "react"
import { routeNames } from "."
import { useUser } from "hooks"
import { LoadingIndicator } from "components/shared"

type PrivateRouteProps = {
  element: React.ReactNode
  permission?: string
}

/**
 * @param {React.ReactNode} element the component to be privated
 * @example `<Route path="path" element={<PrivateRoute element={<HomePage />} />} />`
 */
const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, permission }) => {
  const { isLoggedIn, loading, account } = useUser()

  /**
   * This function will return Route element based on user Authorization.
   * @returns Element our redirect to login page
   */

  if (loading) return <LoadingIndicator />

  return isLoggedIn ? <>{element}</> : <Navigate to={routeNames.login} />
}

export default PrivateRoute
