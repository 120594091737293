import { usePagination, useProjectsAsClaims, useUser } from "hooks"
import { useCallback, useMemo, useState } from "react"
import NotFoundItems from "../NotFoundItems"
import { AccountTypeEnum } from "hooks/useProjectsAsClaims"
import ListingHolder from "containers/holders/ListingHolder"
import { ProjectType } from "types/project"
import calcPageCount from "utils/calcPageCount"
import { Box, Grid, Stack } from "@mui/material"
import TurtleSearchBox from "../TurtleSearchBox"
import { splitArrayBySpecificLength } from "utils"
import ClaimCard from "./ClaimCard"
import { LISTING_PER_PAGE } from "consistants"
import { ClaimType } from "types/claims"
import { DisputeType } from "types/dispute"
import { ClaimsListSkeleton } from "./ClaimsListSkeleton"

type Props = {
  accountType: AccountTypeEnum
  perPage?: number
  defaultClaims?: ClaimType[]
  defaultDisputes?: DisputeType[]
}

const ClaimsList = ({
  accountType,
  perPage = LISTING_PER_PAGE,
  defaultClaims,
  defaultDisputes,
}: Props) => {
  const { account, loading: accLoading } = useUser()
  const [searchVal, setSearchVal] = useState("")
  const { claims, loading } = useProjectsAsClaims(
    accountType,
    account,
    defaultClaims,
    defaultDisputes
  )

  let filteredClaims = useMemo(() => {
    let arr = []
    if (claims?.length > 0) {
      arr = [
        ...claims?.filter((claim) => {
          return claim?.["description"]
            ?.toLowerCase()
            ?.includes(searchVal.toLowerCase())
        }),
      ]
    }
    return arr
  }, [claims, searchVal])

  const {
    currentPage,
    loading: paginationLoading,
    renderPagination,
    setDefault,
  } = usePagination<ProjectType>(undefined, perPage)

  const setDefaultPageHandler = useCallback(
    (val: string) => {
      setSearchVal(val)
      setDefault()
    },
    [setDefault]
  )

  let finalItems = useMemo(() => {
    return (
      splitArrayBySpecificLength(filteredClaims, perPage)?.[currentPage - 1] ??
      []
    )
  }, [currentPage, filteredClaims, perPage])

  let isLoading = loading || paginationLoading || accLoading

  let content = null
  const totalItems = claims?.length ?? 0
  const pageCount = calcPageCount(totalItems, perPage)

  if (!isLoading && finalItems?.length === 0) {
    content = <NotFoundItems title="There is no any claims" />
  }

  if (finalItems?.length) {
    content = (
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={2}>
          {finalItems.map((claim: ClaimType) => {
            return (
              <Grid key={Number(claim.id)} item xs={12} md={6} lg={4}>
                <ClaimCard accountType={accountType} claim={claim} />
              </Grid>
            )
          })}
        </Grid>
      </Box>
    )
  }

  return (
    <ListingHolder
      beforeNode={<TurtleSearchBox onChange={setDefaultPageHandler} />}
      paginateNode={(pageCount > 1 && renderPagination(pageCount)) || null}
    >
      <Stack alignItems={"center"} minHeight={500} sx={{ width: "100%" }}>
        {isLoading && !finalItems.length ? <ClaimsListSkeleton /> : content}
      </Stack>
    </ListingHolder>
  )
}

export default ClaimsList
