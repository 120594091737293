import { Stack } from "@mui/material"
import Loading from "./Loading"

type Props = {
  fullScreen?: boolean
}

const FullSizeLoading = ({ fullScreen = false }: Props) => {
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      height={fullScreen ? "calc(100vh - 100px)" : "100%"}
    >
      <Loading />
    </Stack>
  )
}

export default FullSizeLoading
