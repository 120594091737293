import { Divider, Stack } from "@mui/material";
import Heading from "./Heading";
import { ReactNode } from "react";

type Props = {
  title: string;
  children: ReactNode;
  onClose: () => void;
};

export default function ModalContentWrapper({
  title,
  children,
  onClose,
}: Props) {
  return (
    <Stack>
      <Heading title={title} onClose={onClose} />
      <Divider />
      {children}
    </Stack>
  );
}
