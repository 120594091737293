import { ReactNode, useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "./store";
import { LoadingIndicator } from "components/shared";
import { Stack } from "@mui/material";

type Props = {
  children: ReactNode;
};
export default function ReduxWrapper({ children }: Props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <Provider store={store}>
      {loading ? (
        <Stack
          sx={{
            width: "100%",
            height: "100vh",
            backgroundColor: (t) => `#311558`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingIndicator />
        </Stack>
      ) : (
        children
      )}
    </Provider>
  );
}
