import { t } from "i18next"
import * as Yup from "yup"
import { InputAdornment, Paper, Stack, TextField } from "@mui/material"
import { useFormik } from "formik"
import { useLoading } from "hooks"
import { LoadingButton } from "@mui/lab"
import TitleValueViewer from "../TitleValueViewer"

type WalletPayoutProps = {
  onPayout: (amount: string) => void
}

const WalletPayout = ({ onPayout }: WalletPayoutProps) => {
  const {
    startLoading: startSubmitLoading,
    stopLoading: stopSubmitLoading,
    isLoading: isSubmitLoading,
  } = useLoading()
  const { getFieldProps, values, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string().required(
        t("inputValidations:this_field_is_required")
      ),
    }),
    onSubmit: async (values) => {
      try {
        startSubmitLoading()
        await onPayout(values.amount)
        stopSubmitLoading()
      } catch (error) {
        stopSubmitLoading()
      }
    },
  })

  let viewContent = (
    <Stack
      component={"form"}
      onSubmit={handleSubmit}
      width={"100%"}
      alignItems={"center"}
      spacing={4}
    >
      <TextField
        label={t("Amount")}
        {...getFieldProps("amount")}
        type="number"
        error={!!errors.amount && !!touched.amount}
        helperText={!!touched.amount && errors.amount}
        variant="outlined"
        InputProps={{
          startAdornment: <InputAdornment position="start">ETH</InputAdornment>,
        }}
      />
      <LoadingButton
        loading={isSubmitLoading}
        disabled={!values.amount}
        type="submit"
        variant="contained"
      >
        {t("confirm")}
      </LoadingButton>
    </Stack>
  )

  return (
    <Paper sx={{ height: "100%" }}>
      <TitleValueViewer
        titleVariant="bodyRegular"
        spacing={4}
        direction="column"
        title={t("payout")}
        value={viewContent}
      />
    </Paper>
  )
}

export default WalletPayout
