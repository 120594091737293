import { ProjectType } from "types/project"

export enum DisputeStatusEnum {
  "Waiting" = 0,
  "Finished" = 1,
}

export enum DisputeResultEnum {
  "Agreed" = 1,
  "Disagreed" = 2,
  "Equal" = 3,
}

export enum DisputeTypeEnum {
  "Toapprove" = 0,
  "Todisapprove " = 1,
}
export type DisputeType = {
  0: string
  1: string[]
  2: any[]
  3: any[]
  4: any[]
  5: any[]
  6: bigint
  7: bigint
  8: bigint
  9: bigint
  10: bigint
  11: bigint
  12: bigint
  13: bigint
  ballot: any[]
  cast: string[]
  contractor: string
  encodeVotes: any[]
  claimId: bigint
  disputeType: bigint
  cost: bigint
  title?: string
  description?: string
  id: bigint
  project?: ProjectType
  judges: string[]
  projectId: bigint
  result: bigint
  status: bigint
  timestamp: bigint
  voted: any[]
}
