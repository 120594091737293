/**
 * Place all route names here
 * @example ```js
 * {
 *     "assets": "/assets",
 *     "assetDetails": "/assets/:id"
 * }
 * ```
 */

const routes = {
  login: "/login",
  contractor: {
    index: "/contractor",
    activities: {
      index: "my-activities",
      contract: "contract/:id",
    },
    disputes: {
      index: "disputes",
      details: "details/:id",
    },
    startProject: {
      index: "start-project",
      detail: {
        index: "contract-detail/:id",
        add: {
          index: "add-detail",
          overview: "overview",
        },
      },
    },
    claims: {
      index: "claims",
      details: "details/:id",
    },
    wallet: {
      index: "wallet",
    },
  },
  employer: {
    index: "/employer",
    myActivities: {
      index: "my-activities",
      details: "details/:id",
    },
    allContracts: {
      index: "all-contracts",
      show: "show/:id",
    },
    wallet: {
      index: "wallet",
    },
    disputes: {
      index: "disputes",
      details: "details/:id",
    },
    claims: {
      index: "claims",
      details: "details/:id",
    },
  },
  jury: {
    index: "/jury",
    history: {
      index: "history",
      details: "details/:id",
    },
    activeCases: {
      index: "active-cases",
      details: "details/:id",
    },
    wallet: {
      index: "wallet",
    },
    register: {
      index: "register",
    },
  },
  selectRole: {
    index: "select-role",
  },
  registerAsJudge: {
    index: "register-as-judge",
  },
}

export default routes
