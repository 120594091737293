import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom"
import React, { useEffect, useState } from "react"
import LazyRoute from "./LazyRoute"
import PrivateRoute from "./PrivateRoute"
import RouteNames from "./routeNames"
import { useSDK } from "@metamask/sdk-react"

import PublicRoute from "./PublicRoute"
import { LoadingIndicator } from "components/shared"
import { useAppDispatch } from "store/store"
import { setAccount, setType } from "store/slices/auth"
import RestrictJury from "./JuryRestriction"

/**
 * All routes are placed here. If you wish to lazy load a page, refer to the example below
 * @example ```jsw
 * const UserDashboard = React.lazy(() => import("../panels/user/pages/Dashboard/index.tsx"))
 * .
 * .
 * .
 * <Route path="dashboard" element={<LazyRoute element={<UserDashboard />} />} />
 * ```
 * **Important note:**
 * You must export default the page and import that default export with `React.lazy` in order for lazy loading to work.
 */

//Login page
const LoginFields = React.lazy(() => import("pages/login-page"))

//Role Selection page
const SelectRolePage = React.lazy(() => import("pages/SelectRole"))

//Contractor component ----------------------------------------------------------------
//activities
const Activities = React.lazy(() => import("pages/Contractor/activities"))
const SingleActivity = React.lazy(
  () => import("pages/Contractor/activities/show")
)
//projects
const StartProject = React.lazy(() => import("pages/Contractor/StartProject"))
const ContractDetails = React.lazy(
  () => import("pages/Contractor/StartProject/Details")
)
const AddContractDetails = React.lazy(
  () => import("pages/Contractor/StartProject/AddDetails")
)
const ContractOverview = React.lazy(
  () => import("pages/Contractor/StartProject/Overview")
)
//claims
const ClaimsPage = React.lazy(() => import("pages/Contractor/claims"))
const ClaimDetailsPage = React.lazy(
  () => import("pages/Contractor/claims/details")
)
const EmployerClaimDetailsPage = React.lazy(
  () => import("pages/Employer/Claims/details")
)
//wallet
const WalletPage = React.lazy(() => import("pages/Contractor/wallet"))
//Contractor component ----------------------------------------------------------------

//Employer component ----------------------------------------------------------------
const EmployerActivities = React.lazy(() => import("pages/Employer/activities"))

const EmployerAllContracts = React.lazy(
  () => import("pages/Employer/AllContracts")
)
const EmployerDisputes = React.lazy(() => import("pages/Contractor/Dispute"))
const EmployerClaimes = React.lazy(() => import("pages/Employer/Claims"))

const EmployerWallet = React.lazy(() => import("pages/Employer/wallet"))
const ContractorDisputePage = React.lazy(
  () => import("pages/Contractor/Dispute/show")
)
const EmployerContractDetails = React.lazy(
  () => import("components/shared/Activities/Employer/Details")
)

//Jury components ----------------------------------------------------------------
const JuryHistoryPage = React.lazy(() => import("pages/Jury/History"))
const JudgeHistoryDetailsPage = React.lazy(
  () => import("pages/Jury/History/show")
)
const JuryActiveCases = React.lazy(() => import("pages/Jury/ActiveCasses"))
const JudgeCaseDetails = React.lazy(
  () => import("pages/Jury/ActiveCasses/Show")
)

const JuryRestrictionPage = React.lazy(() => import("./JuryRestriction"))

const JuryWalletPage = React.lazy(() => import("pages/Jury/Wallet"))

const RouterConfig = () => {
  const { login, employer, contractor, jury, selectRole } = RouteNames

  const navigate = useNavigate()

  const { pathname } = useLocation()

  const [isLoadingAuth, setAuthLoading] = useState(true)

  const { connected, error, account, connecting } = useSDK()

  useEffect(() => {
    if (!connecting || connected || error || !account) {
      setAuthLoading(false)
    }
  }, [connecting, connected, error, account])

  const dispatch = useAppDispatch()
  useEffect(() => {
    let splittedPath = pathname.split("/")?.[1]
    if (!!account) {
      dispatch(setAccount(account))
    } else {
      dispatch(setAccount(null))
    }
    if (splittedPath === "employer") {
      navigate("/employer/my-activities")
      dispatch(setType(splittedPath))
    } else if (splittedPath === "contractor") {
      navigate("/contractor/my-activities")
      dispatch(setType(splittedPath))
    } else if (splittedPath === "jury") {
      navigate("/jury")
      dispatch(setType(splittedPath))
    }
  }, [account])

  return isLoadingAuth ? (
    <LoadingIndicator fullScreen type="circular" />
  ) : (
    <Routes>
      <Route
        path={login}
        element={
          <LazyRoute element={<PublicRoute element={<LoginFields />} />} />
        }
      />
      <Route
        path={selectRole.index}
        element={<LazyRoute element={<SelectRolePage />} />}
      />
      <Route path={"/"} element={<LazyRoute element={<Outlet />} />}>
        <Route index element={<Navigate to={contractor.index} />} />
        <Route
          element={
            <LazyRoute element={<PrivateRoute element={<Outlet />} />} />
          }
        >
          {/* Contractor routes */}
          <Route
            path={contractor.index}
            element={<LazyRoute element={<Outlet />} />}
          >
            <Route
              index
              element={<Navigate to={contractor.activities.index} />}
            />
            <Route path={contractor.activities.index}>
              <Route index element={<LazyRoute element={<Activities />} />} />
              <Route
                path={contractor.activities.contract}
                element={<LazyRoute element={<SingleActivity />} />}
              />
            </Route>
            <Route path={contractor.startProject.index}>
              <Route index element={<LazyRoute element={<StartProject />} />} />
              <Route path={contractor.startProject.detail.index}>
                <Route
                  index
                  element={<LazyRoute element={<ContractDetails />} />}
                />
                <Route path={contractor.startProject.detail.add.index}>
                  <Route
                    index
                    element={<LazyRoute element={<AddContractDetails />} />}
                  />
                  <Route
                    path={contractor.startProject.detail.add.overview}
                    element={<LazyRoute element={<ContractOverview />} />}
                  />
                </Route>
              </Route>
            </Route>
            <Route path={contractor.claims.index}>
              <Route index element={<LazyRoute element={<ClaimsPage />} />} />
              <Route
                path={contractor.claims.details}
                element={<LazyRoute element={<ClaimDetailsPage />} />}
              />
            </Route>
            <Route path={contractor.disputes.index}>
              <Route
                index
                element={<LazyRoute element={<EmployerDisputes />} />}
              />
              <Route
                path={contractor.disputes.details}
                element={<LazyRoute element={<ContractorDisputePage />} />}
              />
            </Route>
            <Route path={contractor.wallet.index}>
              <Route index element={<LazyRoute element={<WalletPage />} />} />
            </Route>
          </Route>
          {/* Contractor routes */}
          {/* Employer routes */}
          <Route
            path={employer.index}
            element={<LazyRoute element={<Outlet />} />}
          >
            <Route
              index
              element={<Navigate to={employer.myActivities.index} />}
            />
            <Route path={employer.myActivities.index}>
              <Route
                index
                element={<LazyRoute element={<EmployerActivities />} />}
              />
              <Route
                path={employer.myActivities.details}
                element={<LazyRoute element={<EmployerContractDetails />} />}
              />
            </Route>
            <Route path={employer.allContracts.index}>
              <Route
                index
                element={<LazyRoute element={<EmployerAllContracts />} />}
              />
              <Route
                path={employer.allContracts.show}
                element={<LazyRoute element={<EmployerContractDetails />} />}
              />
            </Route>
            <Route path={employer.disputes.index}>
              <Route index element={<LazyRoute element={<>disputes</>} />} />
              <Route
                path={employer.disputes.details}
                element={<LazyRoute element={<ContractorDisputePage />} />}
              />
            </Route>
            <Route path={employer.wallet.index}>
              <Route
                index
                element={<LazyRoute element={<EmployerWallet />} />}
              />
            </Route>
            <Route path={employer.claims.index}>
              <Route
                index
                element={<LazyRoute element={<EmployerClaimes />} />}
              />
              <Route
                path={employer.claims.details}
                element={<LazyRoute element={<EmployerClaimDetailsPage />} />}
              />
            </Route>
          </Route>
          {/* Employer routes */}
          {/* Jury routes */}
          <Route
            path={jury.index}
            element={
              <LazyRoute
                element={<JuryRestrictionPage element={<Outlet />} />}
              />
            }
          >
            <Route index element={<Navigate to={jury.history.index} />} />
            <Route path={jury.history.index}>
              <Route
                index
                element={<LazyRoute element={<JuryHistoryPage />} />}
              />
              <Route
                path={jury.history.details}
                element={<LazyRoute element={<JudgeHistoryDetailsPage />} />}
              />
            </Route>
            <Route path={jury.activeCases.index}>
              <Route
                index
                element={<LazyRoute element={<JuryActiveCases />} />}
              />
              <Route
                path={jury.activeCases.details}
                element={<LazyRoute element={<JudgeCaseDetails />} />}
              />
            </Route>
            <Route path={jury.wallet.index}>
              <Route
                index
                element={<LazyRoute element={<JuryWalletPage />} />}
              />
            </Route>
          </Route>
          {/* Jury routes */}
        </Route>
      </Route>
    </Routes>
  )
}

export default RouterConfig
