import { useEffect, useState } from "react"
import { getTimeformat } from "utils"

const useTimer = (timeout: number) => {
  let timeouter: NodeJS.Timeout

  //set local time in seconds
  const [localTimer, setLocalTimer] = useState<number | null>(null)
  const [isDisabled, setDisabled] = useState<boolean>(false)

  //function for reset counter
  const resetTimer = () => {
    clearTimeout(timeouter)
    //convert miliseconds to seconds
    setLocalTimer(timeout / 1000)
    setDisabled(false)
  }
  //function for stop timer
  const stopTimer = () => {
    resetTimer()
    setDisabled(true)
  }
  //function for start timer
  const startTimer = () => {
    stopTimer()
    timeouter = setInterval(() => {
      setLocalTimer((val) => {
        if (val >= 1) {
          return val - 1
        } else {
          resetTimer()
          return 0
        }
      })
    }, 1000)
  }

  const { time } = getTimeformat(localTimer)

  useEffect(() => {
    return () => {
      resetTimer()
    }
  }, [])

  return {
    startTimer,
    stopTimer,
    time,
    resetTimer,
    seconds: localTimer,
    isDisabled,
  }
}

export default useTimer
