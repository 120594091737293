import { Navigate } from "react-router-dom"

import React from "react"

import { useUser } from "hooks"
import routes from "./routeNames"

type PublicRouteProps = {
  element: React.ReactNode
  permission?: string
}

/**
 * @param {React.ReactNode} element the component to be privated
 * @example `<Route path="path" element={<PublicRoute element={<HomePage />} />} />`
 */
const PublicRoute: React.FC<PublicRouteProps> = ({ element, permission }) => {
  const { isLoggedIn } = useUser()

  /**
   * This function will return Route element based on user Authorization.
   * @returns Element our redirect to login page
   */

  if (isLoggedIn) return <Navigate to={`/${routes.selectRole.index}`} />

  return <>{element}</>
}

export default PublicRoute
