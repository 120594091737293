import { TextField } from "@mui/material"
import { ChangeEvent, useState, useCallback } from "react"
import SearchIcon from "@mui/icons-material/Search"
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined"

type Props = {
  placeholder?: string
  onChange?: (value: string) => void
  loading?: boolean
}

const SearchBox = ({
  placeholder = "Search",
  onChange,
  loading = false,
}: Props) => {
  const [value, setValue] = useState("")

  const changeInputValueHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let targetValue = e.target.value
      setValue(targetValue)
      if (onChange) onChange(targetValue)
    },
    [onChange]
  )

  return (
    <TextField
      value={value}
      variant="outlined"
      onChange={changeInputValueHandler}
      placeholder={placeholder}
      InputProps={{
        style: { border: "none" },
        startAdornment: loading ? (
          <HourglassEmptyOutlinedIcon />
        ) : (
          <SearchIcon />
        ),
      }}
      sx={{
        "& input": {
          px: 1,
          "::placeholder": { color: (t) => t.palette.common.white, opacity: 1 },
        },
        bgcolor: (t) => t.palette.secondary.dark,
        borderRadius: 2,
        "& fieldset": { outlined: "none", border: "none" },
      }}
    />
  )
}

export default SearchBox
