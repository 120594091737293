import { ProjectType } from "types/project"
import SmartContractService from "./smart-contract-service"
import { fromWeiToString } from "utils"
import { DisputeType } from "types/dispute"
import { toast } from "sonner"

class Dispute {
  smartContractorService: SmartContractService
  project: ProjectType
  constructor(
    smartContractorService: SmartContractService,
    project?: ProjectType
  ) {
    this.smartContractorService = smartContractorService
    if (project) this.project = project
  }

  async registerDispute(value: bigint, registerFee: number) {
    if (!this.project) return null
    const project = this.project
    const fee = +fromWeiToString(value) / registerFee
    const smartContractWrite =
      await this.smartContractorService.getWriteInstance()
    const res = await smartContractWrite
      .setMethodName("registerDispute")
      .setArgs([project.id])
      .setValue(fee)
      .do()
    return res
  }
  async disputeToApprove(value: bigint, registerFee: number, claimId: bigint) {
    if (!this.project || claimId === undefined)
      return toast.warning("there is no any project or claim")

    const project = this.project
    const fee = +fromWeiToString(value) / registerFee
    const smartContractWrite =
      await this.smartContractorService.getWriteInstance()
    const res = await smartContractWrite
      .setMethodName("disputeToApproveClaim")
      .setArgs([project.id, claimId])
      .setValue(fee)
      .do()
    return res
  }
  async disputeToDisapprove(
    value: bigint,
    registerFee: number,
    claimId: bigint
  ) {
    if (!this.project || claimId === undefined) return null
    const project = this.project
    const fee = +fromWeiToString(value) / registerFee
    const smartContractWrite =
      await this.smartContractorService.getWriteInstance()
    const res = await smartContractWrite
      .setMethodName("disputeToDisapproveClaim")
      .setArgs([project.id, claimId])
      .setValue(fee)
      .do()
    return res
  }
  async getContractorDisputs() {
    const account = this.smartContractorService.account
    if (!!!account) return
    const smartContractRead =
      await this.smartContractorService.getReadInstance()
    const res = await smartContractRead
      .setMethodName("getDisputsByContractorAddress")
      .setArgs([account])
      .do()
    return res
  }
  async finalizeDispute(dispute: DisputeType) {
    const smartContractWrite =
      await this.smartContractorService.getWriteInstance()
    const res = await smartContractWrite
      .setMethodName("finalizeDispute")
      .setArgs([dispute.projectId, dispute.id])
      .do()
    return res
  }
  async getDisputesByProjectId(projectId: number) {
    const smartContractWrite =
      await this.smartContractorService.getReadInstance()
    const res = await smartContractWrite
      .setMethodName("getDisputesByProjectId")
      .setArgs([projectId])
      .do()
    return res
  }

  async getDisputeById(disputeId: number) {
    const readSc = await this.smartContractorService.getReadInstance()
    return await readSc
      .setMethodName("getDisputeById")
      .setArgs([disputeId])
      .do()
  }
}

export default Dispute
