import type {} from "@mui/lab/themeAugmentation"

import { Theme, alpha, createTheme } from "@mui/material"
import { red } from "@mui/material/colors"

import CssBaselineOverrides from "./CssBaselineOverrides"
import { lightPalette } from "../palettes"
import typographyVariantsGenerator from "./typographyVariantsGenerator"

/**
 * Place all theme related properties here (except for palette)
 * @param {boolean} dark is used to switch theme
 * Since designs currently have only one theme, don't provide dark argument
 */

const create = (dark?: boolean): Theme => {
  const palette = lightPalette

  const primaryColor = lightPalette.primary as any
  const secondaryColor = lightPalette.secondary as any

  const theme = createTheme({
    breakpoints: {
      keys: ["xs", "sm", "md", "lg", "xl", "xxl"] as any,
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        xxl: 1536,
      } as any,
    },
    palette: lightPalette,
    direction: "ltr",
    typography: {
      fontFamily: "Epilogue",
    },
    components: {
      /* CssBaseline overrides (mostly because of typography) */
      MuiCssBaseline: CssBaselineOverrides,
      MuiFab: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            maxWidth: "1536px !important",
          },
        },
      },
      MuiLoadingButton: {
        variants: [
          {
            props: { variant: "contained", color: "error" },
            style: {
              ":hover": {
                backgroundColor: red[900],
              },
            },
          },
          {
            props: { variant: "contained", color: "primary" },
            style: {
              ":hover": {
                backgroundColor: alpha(primaryColor.main, 0.7),
              },
            },
          },
          {
            props: { variant: "outlined" },
            style: {
              ":hover": {
                backgroundColor: secondaryColor.dark,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              backgroundColor: `${alpha(primaryColor.light, 0.13)} !important`,
              color: `${alpha(primaryColor.main, 0.4)} !important`,
            },
            "&:hover": {
              backgroundColor: primaryColor.main,
            },
            "&.MuiLoadingButton-loading	": {
              backgroundColor: primaryColor.main,
            },
            "& .MuiLoadingButton-loadingIndicator ": {
              color: primaryColor.main,
            },
          },
        },
      },
      MuiSvgIcon: {
        variants: [
          {
            props: { fontSize: "large" },
            style: { width: 34, height: 34 },
          },
          {
            props: { fontSize: "medium" },
            style: { width: 28, height: 28 },
          },
        ],
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            padding: "16px",
            boxShadow: "none",
            backgroundColor: secondaryColor.dark,
            [`&.MuiPopover-paper`]: {
              marginTop: 30,
              paddingRight: 8,
              paddingLeft: 8,
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.25)",
            },
          },
        },
      },

      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            "& .MuiFormControlLabel-label": {
              color: lightPalette.text.secondary,
              fontSize: "14px",
            },
            margin: 0,
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            margin: "5px 10px",
            width: 16,
            height: 16,
            backgroundColor:
              lightPalette.mode === "dark" ? "#394b59" : "#f5f8fa",
            "&:hover": {
              backgroundColor:
                lightPalette.mode === "dark" ? "#394b59" : "#f5f8fa",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            borderRadius: "8px !important",
          },
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
          },
        },
      },
      MuiSwitch: {
        defaultProps: {
          disableFocusRipple: true,
          disableRipple: true,
          disableTouchRipple: true,
        },
        styleOverrides: {
          root: {
            padding: 2,
            overflow: "initial",
            width: 40,
            height: 25,
            [`& .MuiSwitch-track`]: {
              borderRadius: 20,
            },
            [`& .MuiSwitch-switchBase`]: {
              padding: 0,
              top: 2.5,
              boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
            },
          },
        },
      },
      MuiTextField: {
        variants: [
          {
            props: { variant: "filled" },
            style: {
              "& .MuiFilledInput-root": {
                overflow: "hidden",
                "&:hover": {
                  backgroundColor: lightPalette.divider,
                },
                backgroundColor: lightPalette.divider,
                color: lightPalette.text.secondary,
                "& input": {
                  padding: "18px 10px",
                },
                "&.Mui-focused": {
                  backgroundColor: lightPalette.divider,
                },
              },
            },
          },
          {
            props: { variant: "outlined" },
            style: {
              "& label": {
                color: secondaryColor.main,
              },
              "& .MuiOutlinedInput-root": {
                "& .MuiInputAdornment-root p": {
                  color: primaryColor.main,
                },
                backgroundColor: "transparent !important",
                color: lightPalette.text.secondary,
              },
            },
          },
        ],
        defaultProps: {
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            "& .MuiFormHelperText-root": {
              "&.Mui-error": {
                color: "#f44336",
              },
              fontSize: 12,
              color: lightPalette.text.secondary,
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            [`.MuiFormLabel-filled + .MuiOutlinedInput-root`]: {
              backgroundColor: `#ffffff`,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: 14,
            marginLeft: 0,
            marginRight: 0,
            fontSize: 14,
            lineHeight: "19px",
            color: "rgba(0,0,0,0.6)",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            [`& fieldset`]: {
              borderColor: "#8F8DE0 !important",
            },
            [`&:hover fieldset`]: {
              borderColor: "#8F8DE0 !important",
            },
          },
        },
      },
      MuiFormGroup: {
        styleOverrides: {
          root: {
            [`& .MuiSwitch-root`]: {
              marginRight: 8,
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            [`&::after,&::before`]: {
              display: "none",
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              backgroundColor: `${alpha(primaryColor.light, 0.13)} !important`,
              color: `${alpha(primaryColor.main, 0.4)} !important`,
            },
            borderRadius: "8px",
            textTransform: "capitalize",
            whiteSpace: "nowrap",
            fontSize: 12,
            minWidth: 150,
            "&:hover": {
              backgroundColor: primaryColor.main,
            },
            [`&.MuiButton-text`]: {
              boxShadow: "none !important",
            },
            "&.MuiButton-outlined": {
              color: "#8F8DE0",
              borderColor: "#8F8DE0",
            },
          },
        },
        variants: [
          {
            props: { variant: "outlined" },
            style: {
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },
          {
            props: { variant: "contained", color: "error" },
            style: {
              "&:hover": {
                backgroundColor: red[900],
              },
            },
          },
          {
            props: { variant: "text" },
            style: {
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },
        ],
      },
      MuiBadge: {
        styleOverrides: {
          dot: {
            backgroundColor: palette.extended_colors.green.main,
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            background: `linear-gradient(180deg, ${alpha(
              lightPalette.gradientPrimaryStart.main,
              0.65
            )} 20%, ${lightPalette.gradientPrimaryEnd.main} 70%)`,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: primaryColor.main,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            margin: "16px 0",
            width: "auto",
            "& .MuiTabs-scroller": {
              "& span": {
                display: "none",
              },
              "& .MuiTabs-flexContainer": {
                justifyContent: "center",
                "& .MuiTab-root": {
                  minWidth: 120,
                  "@media(min-width:1100px)": {
                    minWidth: 150,
                  },
                  textTransform: "capitalize",
                },

                "& .Mui-selected": {
                  border: "1px solid",
                  borderRadius: "8px",
                },
              },
            },
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: 5,
            height: 10,
            backgroundColor: primaryColor.main,
            "&.MuiLinearProgress-colorPrimary": {
              backgroundColor: lightPalette.grey[200],
            },
            "& .MuiLinearProgress-bar	": {
              borderRadius: 5,
            },
          },
        },
        variants: [
          {
            props: { color: "success" },
            style: {
              "&.MuiLinearProgress-colorSuccess": {
                backgroundColor: lightPalette.grey[200],
              },
              "& .MuiLinearProgress-bar	": {
                borderRadius: 5,
                backgroundColor: lightPalette.extended_colors.green.main,
              },
            },
          },
          {
            props: { color: "error" },
            style: {
              "&.MuiLinearProgress-colorError": {
                backgroundColor: lightPalette.grey[200],
              },
              "& .MuiLinearProgress-bar	": {
                borderRadius: 5,
                backgroundColor: lightPalette.extended_colors.light_red.main,
              },
            },
          },
        ],
      },
      MuiTooltip: {
        styleOverrides: {
          popper: {
            marginTop: "10px !important",
          },
          tooltip: {
            backgroundColor: alpha(primaryColor.main, 1),
            maxWidth: 250,
            padding: "6px 8px 4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 !important",
            textAlign: "center",
            minWidth: 80,
            minHeight: "28px",
            lineHeight: 1.5,
            "& .MuiTooltip-arrow": {
              color: alpha(primaryColor.main, 0.4),
            },
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            fontSize: 16,
            "&.Mui-selected": {
              backgroundColor: primaryColor.main,
              color: secondaryColor.dark,
              paddingTop: 4,
              ":hover": {
                backgroundColor: primaryColor.light,
              },
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            backgroundColor: lightPalette.extended_colors.status.main,
            color: "#fff",
            fontSize: 13,
            "& .MuiAlert-message": {
              marginTop: "2px !important",
              height: "auto !important",
            },
          },
        },
      },
    },
  })

  const media = theme.breakpoints.down("md")

  /* Responsive typographies here (only base typographies(for example header. not headerBold)) or default MUI */
  theme.typography.header = {
    fontSize: "52px",
    lineHeight: "63px",
    [media]: {
      fontSize: "40px",
      lineHeight: "45px",
    },
  }

  theme.typography.large = {
    fontSize: "48px",
    lineHeight: "58px",
    [media]: {
      fontSize: "22px",
      lineHeight: "28px",
    },
  }
  theme.typography.heading = {
    fontSize: "32px",
    lineHeight: "39px",
    [media]: {
      fontSize: "24px",
      lineHeight: "29px",
    },
  }
  theme.typography.subtitle = {
    fontSize: "20px",
    lineHeight: "30px",
    [media]: {
      fontSize: "18px",
      lineHeight: "25px",
    },
  }
  theme.typography.body = {
    fontSize: "16px",
    lineHeight: "20px",
    [media]: {
      fontSize: "14px",
      lineHeight: "22px",
    },
  }
  theme.typography.small = {
    fontSize: "14px",
    lineHeight: "28px",
    [media]: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  }
  theme.typography.xsmall = {
    fontSize: "12px",
    lineHeight: "22px",
    [media]: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  }
  theme.typography = {
    ...theme.typography,
    ...typographyVariantsGenerator(theme.typography),
  }

  return theme
}

export default create
