import { getTimeformat } from "utils"
import CalcRemainedTime from "../CalcRemainedTime"
import CountdownViewer from "../CountdownViewer"

interface Props {
  timer: number
  onEnd?: () => void
  description?: string
}

export const TimerNode = ({ timer, onEnd, description = "" }: Props) => {
  return (
    <CalcRemainedTime
      timeOverDesc=""
      onEnd={onEnd}
      showCountdown={false}
      targetTime={timer}
    >
      {({ seconds }) => {
        let timeout = seconds
        let timer = getTimeformat(timeout, true, true).labeledTime
        let timeoutNode = (
          <CountdownViewer description={description} time={timer} />
        )

        return <>{timeoutNode}</>
      }}
    </CalcRemainedTime>
  )
}
