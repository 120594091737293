import { Button } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"

export default function BackBtn({
  title = "Back",
  href,
  variant = "contained",
}: {
  title?: string
  href?: string
  variant?: "outlined" | "contained"
}) {
  const navigate = useNavigate()
  return (
    <Button
      variant={variant}
      onClick={() => (href ? navigate(href) : navigate(-1))}
    >
      {title}
    </Button>
  )
}
