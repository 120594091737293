import { AttachmentType } from "types/attachment";

const useUploadedImage = (file: AttachmentType | File) => {
  if (typeof file === "object" && Object.keys(file).includes("original")) {
    return (file as AttachmentType)?.original as string;
  } else if (typeof file === "string") {
    return file;
  } else {
    try {
      return URL.createObjectURL(file as File);
    } catch (error) {
      return null;
    }
  }
};

export default useUploadedImage;
