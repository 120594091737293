import { CloseIcon } from "components/icons";
import { useUploadedImage } from "hooks";
import { AttachmentType } from "types/attachment";
import { Box, IconButton, alpha } from "@mui/material";
import React from "react";

type Props = {
  file: File | AttachmentType;
  width?: number;
  height?: number;
  onRemove?: () => void;
};

const SmallFileViewer = ({
  width = 90,
  height = 90,
  file,
  onRemove,
}: Props) => {
  const imageFile = useUploadedImage(file);

  let currentView = (
    <Box
      sx={{
        width,
        height,
        borderRadius: "6px",
        backgroundImage: `url(${imageFile})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    />
  );

  let removeAction = null;

  if (!!onRemove) {
    removeAction = (
      <IconButton
        onClick={onRemove}
        sx={{
          position: "absolute",
          top: 2,
          left: 2,
          width: 20,
          p: 0,
          height: 20,
          bgcolor: (t) => alpha(t.palette.common.black, 0.5),
          "&:hover": {
            bgcolor: (t) => alpha(t.palette.common.black, 0.5),
          },
        }}
      >
        <CloseIcon size={12} />
      </IconButton>
    );
  }

  return (
    <Box className='sm-file-viewer' sx={{ position: "relative" }}>
      {removeAction}
      {currentView}
    </Box>
  );
};

export default SmallFileViewer;
