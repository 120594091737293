class SmartContractAbi {
  private apiKey = "46H7KNXBFBWBAUVU3KQK9ZG9IZA3CUGW4B";
  public contractAbi: any[] = [];
  //Fetch contract
  public async fetchContract(contractAddress: string) {
    try {
      const res = await fetch(
        `https://api-sepolia.etherscan.io/api?module=contract&action=getabi&address=${contractAddress}&tag=latest&apikey=${this.apiKey}`
      );
      const data = await res.json();
      const contractABI = JSON.parse(data.result);
      return contractABI;
    } catch (e) {
      return [];
    }
  }
}

export default SmartContractAbi;
