import { usePagination, useUser } from "hooks"
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import ListingHolder from "containers/holders/ListingHolder"
import calcPageCount from "utils/calcPageCount"
import { Stack } from "@mui/material"
import TurtleSearchBox from "../TurtleSearchBox"
import { getRevertReason, splitArrayBySpecificLength } from "utils"
import { READ_CONTRACT_ADDRESS } from "consistants"
import { DisputeStatusEnum, DisputeType } from "types/dispute"
import SmartContractService from "services/smart-contract-service"

const DISPUTES_PER_PAGE = 3
type Props = {
  perPage?: number
  isHistory?: boolean
  isActiveJudge?: boolean
  methodName: string
  children: ({
    disputes,
    loading,
  }: {
    disputes: DisputeType[]
    loading: boolean
  }) => ReactNode
}

const DisputesFetcher = ({
  perPage = DISPUTES_PER_PAGE,
  isHistory = false,
  isActiveJudge = false,
  children,
  methodName,
}: Props) => {
  const { account, loading: accLoading } = useUser()
  const [searchVal, setSearchVal] = useState("")
  const [loading, setLoading] = useState(false)
  const [disputes, setDisputes] = useState<DisputeType[]>(undefined)

  let filteredDisputes = useMemo(() => {
    let arr = []
    if (disputes?.length > 0) {
      arr = [
        ...disputes?.filter((claim) => {
          return claim?.["description"]
            ?.toLowerCase()
            ?.includes(searchVal.toLowerCase())
        }),
      ]
    }
    return arr
  }, [disputes, searchVal])

  const {
    currentPage,
    loading: paginationLoading,
    renderPagination,
    setDefault,
  } = usePagination<DisputeType>(undefined, perPage)

  const setDefaultPageHandler = useCallback(
    (val: string) => {
      setSearchVal(val)
      setDefault()
    },
    [setDefault]
  )

  let finalItems = useMemo(() => {
    return (
      splitArrayBySpecificLength(filteredDisputes, perPage)?.[
        currentPage - 1
      ] ?? []
    )
  }, [currentPage, filteredDisputes, perPage])

  let isLoading =
    loading || paginationLoading || accLoading || disputes === undefined

  const totalItems = disputes?.length ?? 0
  const pageCount = calcPageCount(totalItems, perPage)

  useEffect(() => {
    const getDisputes = async () => {
      const smartContractService = new SmartContractService(
        account,
        READ_CONTRACT_ADDRESS
      )

      const readInstance = await smartContractService.getReadInstance()

      try {
        setLoading(true)
        const disputes = await readInstance
          .setMethodName(methodName)
          .setArgs([account])
          .do()
        if (isHistory) {
          setDisputes(
            disputes
              .filter((d) => Number(d.status) !== DisputeStatusEnum.Waiting)
              .reverse()
          )
        } else if (isActiveJudge) {
          setDisputes(
            disputes
              .filter((d) => Number(d.status) === DisputeStatusEnum.Waiting)
              .reverse()
          )
        } else {
          setDisputes(disputes.reverse())
        }
        setLoading(false)
      } catch (error) {
        getRevertReason(error)
        setLoading(false)
      }
    }
    if (!!account) {
      getDisputes()
    }
  }, [account])

  return (
    <ListingHolder
      beforeNode={<TurtleSearchBox onChange={setDefaultPageHandler} />}
      paginateNode={(pageCount > 1 && renderPagination(pageCount)) || null}
    >
      <Stack alignItems={"center"} minHeight={500} sx={{ width: "100%" }}>
        {children({ disputes: finalItems, loading: isLoading })}
      </Stack>
    </ListingHolder>
  )
}

export default DisputesFetcher
