import { READ_CONTRACT_ADDRESS } from "consistants"
import { useEffect, useState } from "react"
import Jury from "services/jury"
import SmartContractService from "services/smart-contract-service"
import { getRevertReason } from "utils"

const useJuryState = (account: string) => {
  const [loading, setLoading] = useState(false)
  const [isJudge, setIsJudge] = useState(false)

  useEffect(() => {
    const getJuryState = async () => {
      const smartContractService = new SmartContractService(
        account,
        READ_CONTRACT_ADDRESS
      )
      const jury = new Jury(smartContractService)
      try {
        setLoading(true)
        const res = await jury.isJudge()
        setIsJudge(res)
        setLoading(false)
      } catch (error) {
        getRevertReason(error)
        setLoading(false)
      }
    }
    getJuryState()
  }, [account])

  return {
    loading,
    isJudge,
  }
}

export default useJuryState
