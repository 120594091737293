import { Pagination } from "@mui/material"
import { LISTING_PER_PAGE } from "consistants"
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { splitArrayBySpecificLength } from "utils"

const usePagination = <T,>(
  items?: T[],
  perPage: number = LISTING_PER_PAGE
): {
  currentPage: number
  renderPagination: (total: number, defaultPage?: number) => ReactNode
  setDefault: () => void
  loading: boolean
  finalItems: T[]
} => {
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageItems, setPageItems] = useState<T[]>([])

  const setDefaultPageHandler = useCallback(() => {
    setCurrentPage(1)
  }, [])

  const changePageHandler = useCallback(
    (e: React.ChangeEvent, page: number) => {
      setLoading(true)
      setTimeout(() => {
        setCurrentPage(page)
        setLoading(false)
      }, 500)
    },
    []
  )

  useEffect(() => {
    setDefaultPageHandler()
  }, [setDefaultPageHandler])

  useEffect(() => {
    if (items !== undefined) {
      setPageItems(items)
    }
  }, [items])

  let finalItems = useMemo(() => {
    return (
      splitArrayBySpecificLength(pageItems, perPage)?.[currentPage - 1] ?? []
    )
  }, [currentPage, pageItems, perPage])

  const pagination = (total: number, defaultPage?: number) => {
    return (
      <Pagination
        color="primary"
        page={currentPage}
        onChange={changePageHandler}
        count={total}
        defaultPage={defaultPage}
      />
    )
  }

  return {
    currentPage,
    renderPagination: pagination,
    setDefault: setDefaultPageHandler,
    loading,
    finalItems,
  }
}

export default usePagination
