import { Stack, SxProps, Theme, Typography } from "@mui/material"
import * as React from "react"

interface IDetailsBoxWithIconProps {
  icon: React.ReactNode
  direction?: "column" | "row"
  title: string
  value: string | React.ReactNode
  sx?: SxProps<Theme>
}

const DetailsBoxWithIcon: React.FunctionComponent<IDetailsBoxWithIconProps> = ({
  icon,
  direction = "row",
  title,
  value,
  sx,
}) => {
  let currentValue = value

  if (typeof value === "string") {
    currentValue = (
      <Typography variant="smallMedium" color={"text.secondary"}>
        {value}
      </Typography>
    )
  }

  return (
    <Stack
      alignItems="center"
      justifyContent={"space-between"}
      direction={direction}
      p={1}
      px={1.5}
      sx={{
        borderRadius: "6px",
        width: "100%",
        bgcolor: (t) => t.palette.extended_colors.status.main,
        ...sx,
      }}
    >
      <Stack
        className="title-node"
        direction="row"
        spacing={1.5}
        alignItems="center"
      >
        {icon}
        <Typography variant="smallMedium" color={"text.primary"}>
          {title}
        </Typography>
      </Stack>
      {currentValue}
    </Stack>
  )
}

export default DetailsBoxWithIcon
