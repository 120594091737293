import { Stack, SxProps, Theme, Typography } from "@mui/material"
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom"
import { ReactNode } from "react"

type Props = {
  description?: string
  sxProp?: SxProps<Theme>
  afterNode?: ReactNode
}

const PassedTimeAlert = ({ description, sxProp, afterNode }: Props) => {
  return (
    <Stack spacing={2} alignItems={"center"} sx={{ width: "100%", ...sxProp }}>
      <HourglassBottomIcon color="primary" />
      <Typography textAlign={"center"}>{description}</Typography>
      {afterNode && afterNode}
    </Stack>
  )
}

export default PassedTimeAlert
