import { ClaimType } from "types/claims"
import { DisputeType } from "types/dispute"

export enum ProjectStatusEnum {
  "active" = 0,
  "closed" = 3,
}

export type ProjectType = {
  areVoted: any[]
  claimers: ClaimType[]
  contractors: string[]
  documents: string
  disputers: DisputeType[]
  title?: string
  duedate: bigint
  duration: bigint
  finisher: string
  id: bigint
  ipfsLink: string
  owner: string
  registrationFee: bigint
  reward: bigint
  status: bigint
}
