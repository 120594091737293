import * as React from "react"

import { Stack, Typography, SxProps, Theme } from "@mui/material"
import { AllVariants } from "mui/theme/declarations"

interface ITitleValueViewerProps {
  title: string
  value: React.ReactNode
  titleVariant?: AllVariants
  titleColor?: string
  sx?: SxProps<Theme>
  icon?: React.ReactNode
  spacing?: number
  direction?: "row" | "row-reverse" | "column" | "column-reverse"
}

const TitleValueViewer: React.FunctionComponent<ITitleValueViewerProps> = ({
  title,
  value,
  sx,
  titleVariant = "smallMedium",
  titleColor = "text.secondary",
  icon,
  direction = "row",
  spacing = 1,
}) => {
  return (
    <Stack
      direction={direction}
      spacing={spacing}
      justifyContent="space-between"
      sx={{
        p: 0.5,
        alignItems: direction === "column" ? "start" : "center",
        width: "100%",
        textTransform: "capitalize",
        ...sx,
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        {icon && icon}
        {title && (
          <Typography
            className="title"
            color={titleColor}
            variant={titleVariant}
          >
            {title}
          </Typography>
        )}
      </Stack>
      {value}
    </Stack>
  )
}

export default TitleValueViewer
