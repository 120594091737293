import { StepIconProps, Tooltip } from "@mui/material"
import { styled } from "@mui/material"
import { useState } from "react"

const StepIconWrapper = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean; loading?: boolean }
}>(({ theme, ownerState }) => {
  let sharedColor = `linear-gradient(136deg, ${theme.palette.primary.light} 0%, ${theme.palette.secondary.light} 50%, ${theme.palette.primary.light} 100%)`
  return {
    backgroundColor: ownerState.loading
      ? theme.palette.extended_colors.status.main
      : theme.palette.grey[700],
    zIndex: 1,
    color: "#fff",
    width: 45,
    height: 45,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    "& svg": { fontSize: 24 },
    ...(ownerState.active && {
      ...(ownerState?.loading
        ? { backgroundColor: theme.palette.extended_colors.status.main }
        : {
            backgroundImage: sharedColor,
            "& svg": { fill: theme.palette.secondary.dark, fontSize: 24 },
            boxShadow: "0 4px 10px 0 rgba(100,100,100,.25)",
          }),
    }),
    ...(ownerState.completed && {
      backgroundImage: sharedColor,
      "& svg": { fill: theme.palette.secondary.dark, fontSize: 24 },
    }),
  }
})

const CustomStepIcon = ({
  active,
  completed,
  description = "",
  hasTooltip,
  icon,
  loading = false,
}: StepIconProps & {
  loading?: boolean
  hasTooltip?: boolean
  description?: string
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  let output = (
    <StepIconWrapper
      aria-owns={open ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      ownerState={{ active, completed, loading }}
    >
      {icon}
    </StepIconWrapper>
  )

  if (hasTooltip) {
    output = (
      <Tooltip title={description}>
        <StepIconWrapper
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          ownerState={{ active, completed, loading }}
        >
          {icon}
        </StepIconWrapper>
      </Tooltip>
    )
  }

  return output
}

export default CustomStepIcon
