export const ASSETS_PATH = "/static"
export const LISTING_PER_PAGE = 6

export const MAIN_CONTRACT_ADDRESS =
  "0xc927aD49BfDDe73A4B85AF03bC9c7F3170DCA1aa"

export const READ_CONTRACT_ADDRESS =
  "0xe97f699bE45eEb0D10660A9665344c3b3A3f2303"

export const SIGN_VERIFY_ADDRESS = "0x33e0ae03F4F78D74fF9D919D23FC9E1e743Ee182"
export const CLAIM_APPROVE_DELAY = 600
export const CLOSE_PROJECT_DELAY = 600
