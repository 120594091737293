import { Stack, Typography } from "@mui/material"
import TimerIcon from "components/icons/timer-icon"

interface Props {
  time: string
  description?: string
}

const CountdownViewer = ({ time, description = "Remained time" }: Props) => {
  return (
    <Stack
      className="countdown-viewer"
      spacing={1.5}
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack
        spacing={1}
        direction={"row"}
        alignItems={"flex-end"}
        justifyContent={"center"}
      >
        <TimerIcon width={22} height={22} color="#fff" />
        <Typography
          lineHeight={"16px"}
          color={"text.secondary"}
          textAlign={"center"}
          variant="bodyMedium"
        >
          {time}
        </Typography>
      </Stack>
      <Typography
        variant="bodyMedium"
        lineHeight={1.5}
        textAlign={"center"}
        sx={{ color: (t) => t.palette.grey["400"] }}
      >
        {description}
      </Typography>
    </Stack>
  )
}

export default CountdownViewer
