import { Stack, Typography, useTheme } from "@mui/material"

export enum EStatusVariant {
  "InProgress" = "in-progress",
  "Waiting" = "waiting",
  "Accepted" = "accepted",
  "Rejected" = "rejected",
  "Default" = "default",
}

type Props = {
  variant: EStatusVariant
  label: string
}

const StatusBox = ({ variant, label }: Props) => {
  const theme = useTheme()

  let statusColor = theme.palette.common.white

  switch (variant) {
    case EStatusVariant.Accepted:
      statusColor = theme.palette.extended_colors.green.main
      break
    case EStatusVariant.InProgress:
      statusColor = theme.palette.extended_colors.blue_sky.main
      break
    case EStatusVariant.Waiting:
      statusColor = theme.palette.warning.main
      break
    case EStatusVariant.Rejected:
      statusColor = theme.palette.extended_colors.light_red.main
      break
    default:
      statusColor = theme.palette.common.white
      break
  }

  return (
    <Stack
      alignItems={"cener"}
      className="status-box"
      bgcolor={(t) => t.palette.extended_colors.status.main}
      justifyContent={"center"}
      p={0.5}
      px={2}
      borderRadius={2}
      sx={{ minWidth: 80 }}
    >
      <Typography
        textAlign={"center"}
        variant="xsmallRegular"
        color={statusColor}
      >
        {label}
      </Typography>
    </Stack>
  )
}

export default StatusBox
