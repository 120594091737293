import { useAppSelector } from "store/store";

const useUser = () => {
  const account = useAppSelector((store) => store.auth.account);

  return {
    isLoggedIn: !!account,
    account,
    loading: !(typeof account === "string" || account === null),
  };
};
export default useUser;
