import { Paper, Stack, Typography } from "@mui/material"
import { t } from "i18next"
import { ReactNode } from "react"
import TitleValueViewer from "../TitleValueViewer"

type Props = {
  value: string
  description: string
  actionNode?: ReactNode
}

const FreezeWalletBalance = ({ value, description, actionNode }: Props) => {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Stack sx={{ width: "100%", height: "100%" }} spacing={1}>
        <TitleValueViewer
          title={t("freeze_balance")}
          value={<Typography>{value}</Typography>}
        />
        <Typography color={"text.disabled"}>{description}</Typography>
      </Stack>
      {actionNode && actionNode}
    </Paper>
  )
}

export default FreezeWalletBalance
