import { Stack } from "@mui/material"
import { NormalStepperType } from "../Stepper/NormalStepper"
import { ScNormalStepper } from ".."
import { TimerNode } from "./TimerNode"

// Extract the type of an individual step

type StepWithoutOptionalNode = Omit<
  NormalStepperType["steps"][number],
  "optionalNode"
>

type Props = { timeout: number } & Omit<NormalStepperType, "steps"> & {
    steps: StepWithoutOptionalNode[]
    onEnd?: () => void
  }

const StepperWithTimeout = ({
  activeStep,
  steps,
  onEnd,
  loading,
  sxProps,
  timeout,
}: Props) => {
  if (loading)
    return (
      <Stack width={"100%"} alignItems={"center"}>
        <ScNormalStepper
          activeStep={0}
          loading={loading}
          steps={[...Array(steps.length)]}
        />
      </Stack>
    )

  return (
    <ScNormalStepper
      hasTooltip
      steps={steps.map((step, i) => {
        let timerNode = <TimerNode onEnd={onEnd} timer={timeout} />
        if (i === 0 || i === steps.length - 1) timerNode = null
        return {
          ...step,
          optionalNode: timerNode,
        }
      })}
      activeStep={activeStep}
      sxProps={sxProps}
    />
  )
}

export default StepperWithTimeout
