import { ProjectType } from "types/project"
import SmartContractService from "./smart-contract-service"

class Claim {
  private smartContractService: SmartContractService
  private project?: ProjectType

  constructor(
    smartContractService: SmartContractService,
    project?: ProjectType
  ) {
    if (project) this.project = project
    this.smartContractService = smartContractService
  }

  async submitClaim() {
    if (!this.project) return null
    const smartContractWrite =
      await this.smartContractService.getWriteInstance()
    const value = 0
    const res = await smartContractWrite
      .setMethodName("submitClaim")
      .setArgs([this.project.id])
      .setValue(value)
      .do()
    return res
  }
  async getClaimesByAddress() {
    const account = this.smartContractService.account
    const smartContractRead = await this.smartContractService.getReadInstance()
    const res = await smartContractRead
      .setMethodName("getClaimesByAddress")
      .setArgs([account])
      .do()
    return res
  }
  async getContractorClaimedSooner() {
    if (!this.project) return null
    const smartContractRead = await this.smartContractService.getReadInstance()
    const res = await smartContractRead
      .setMethodName("getContractorClaimedSooner")
      .setArgs([this.project.id])
      .do()
    return res
  }
  async approveClaim(claimId: BigInt) {
    const smartContractWrite =
      await this.smartContractService.getWriteInstance()
    const res = await smartContractWrite
      .setMethodName("approveClaim")
      .setArgs([this.project.id, claimId])
      .setValue(0)
      .do()
    return res
  }

  async disapproveClaim(claimId: BigInt) {
    const project = this.project
    if (!!!project) return
    const smartContractWrite =
      await this.smartContractService.getWriteInstance()
    const res = await smartContractWrite
      .setMethodName("disapproveClaim")
      .setArgs([project.id, claimId])
      .do()
    return res
  }

  async getClaim(claimId: number) {
    const smartContractRead = await this.smartContractService.getReadInstance()
    const res = await smartContractRead
      .setMethodName("getClaimById")
      .setArgs([claimId])
      .do()
    return res
  }

  async getClaimers(projectId: number) {
    const smartContractRead = await this.smartContractService.getReadInstance()
    const res = await smartContractRead
      .setMethodName("getClaimers")
      .setArgs([projectId])
      .do()
    return res
  }
}

export default Claim
