import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import OutlinedFlagTwoToneIcon from "@mui/icons-material/OutlinedFlagTwoTone"
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom"
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined"
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd"
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined"
import { StepperWithTimeout } from "components/shared"
import { ETimelineSteps, useTimelineContext } from "contexts/timelineContext"
import { ReactNode, useCallback } from "react"
import { Stack, Typography } from "@mui/material"
import { ProjectStatusEnum, ProjectType } from "types/project"
import CloseAction from "../CloseAction"
import { useSDK } from "@metamask/sdk-react"
import { t } from "i18next"

type Props = {
  project?: ProjectType
  onClose?: () => void
}

const ActivityTimeline = ({ project, onClose }: Props) => {
  const { account } = useSDK()

  const {
    step,
    timeout,
    changeToDisputeHandler,
    changeToFinalizeHanlder,
    changeToFinishHandler,
    loading,
  } = useTimelineContext()

  const isProjectBelongsToMe =
    project?.owner?.toLowerCase() === account.toLowerCase()

  const changeTimelineStepHandler = useCallback(() => {
    if (step === ETimelineSteps.Active) {
      changeToDisputeHandler()
    } else if (step === ETimelineSteps.Dispute) {
      changeToFinalizeHanlder()
    } else if (step === ETimelineSteps.Finalize) {
      changeToFinishHandler()
    } else {
      return
    }
  }, [
    changeToDisputeHandler,
    changeToFinalizeHanlder,
    changeToFinishHandler,
    step,
  ])

  const isProjectClosed = Number(project?.status) === ProjectStatusEnum.closed

  let afterNode: ReactNode = null
  let beforeNode: ReactNode = null

  if (isProjectBelongsToMe && !isProjectClosed) {
    afterNode = (
      <CloseAction account={account} project={project} onClose={onClose} />
    )
  }

  if (!isProjectBelongsToMe && !isProjectClosed) {
    beforeNode = (
      <Stack alignItems={"center"} width={"100%"} spacing={2}>
        <TimerOutlinedIcon color="info" />
        <Typography variant="bodyMedium">
          {t("sections:wait_for_close_project_after_it_ended")}
        </Typography>
      </Stack>
    )
  }
  if (!isProjectBelongsToMe && isProjectClosed) {
    beforeNode = (
      <Stack alignItems={"center"} width={"100%"} spacing={2}>
        <HourglassBottomIcon color="primary" />
        <Typography variant="bodyMedium">
          {t("sections:the_operation_time_for_this_project_has_ended")}
        </Typography>
      </Stack>
    )
  }

  return (
    <Stack width={"100%"} spacing={4} alignItems={"center"} py={2}>
      {step === ETimelineSteps.Closing && beforeNode}
      <StepperWithTimeout
        onEnd={changeTimelineStepHandler}
        loading={loading}
        timeout={timeout}
        activeStep={step}
        hasTooltip
        steps={[
          {
            label: "Project Created",
            icon: <PlaylistAddIcon />,
            description:
              "The project is created and ready to be selected by the contractor",
          },
          {
            label: "Active",
            icon: <VerifiedUserOutlinedIcon />,
            description:
              "At this stage, all operations are possible except closing the project, such as applying the project, registering claim, dispute, voting and finalizing voting. here employer could approve or disapprove contractor's claim request Also, the contractor can register a dispute.",
          },
          {
            label: "Only Dispute",
            icon: <OutlinedFlagTwoToneIcon />,
            description:
              "here, as in the previous stage, only the contractor can register a dispute, and the employer can't longer disapprove or approve the claim, and the options of judges are the same as in the previous stage.",
          },
          {
            label: "Finalize",
            icon: <DescriptionOutlinedIcon />,
            description:
              "At this step, it is only possible to finalize the dispute request after the end of the voting time",
          },
          {
            label: "Close",
            icon: <HourglassBottomIcon />,
            description:
              "The voting time and also the registration of the claim or dispute has ended at this stage and only the employer can close her/his project.",
          },
        ]}
      />
      {step === ETimelineSteps.Closing && afterNode}
    </Stack>
  )
}

export default ActivityTimeline
