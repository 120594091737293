import { Divider, Paper, Stack, Typography } from "@mui/material"
import { t } from "i18next"
import { FunctionComponent } from "react"
import TitleValueViewer from "../TitleValueViewer"

interface IWalletSummaryProps {
  total: string
  available: string
}

const WalletSummary: FunctionComponent<IWalletSummaryProps> = ({
  total,
  available,
}) => {
  return (
    <Paper>
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <TitleValueViewer
          sx={{ alignItems: "center" }}
          title={t("total_balance")}
          value={<Typography>{total}</Typography>}
        />
        <Divider flexItem sx={{ width: "1px" }} />
        <TitleValueViewer
          sx={{ alignItems: "center" }}
          title={t("available_cost")}
          value={<Typography>{available}</Typography>}
        />
      </Stack>
    </Paper>
  )
}

export default WalletSummary
