import { Stack, SxProps, Theme, Typography, alpha } from "@mui/material"
import PriorityHighIcon from "@mui/icons-material/PriorityHigh"
import { ReactNode } from "react"

type Props = {
  title: string
  icon?: ReactNode
  desc?: string
  sx?: SxProps<Theme>
  afterNode?: ReactNode
}
export default function NotFoundItems({
  title,
  desc,
  sx = {},
  afterNode,
  icon = <PriorityHighIcon />,
}: Props) {
  return (
    <Stack
      direction={"column"}
      spacing={2}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        bgcolor: (t) => alpha(t.palette.common.white, 0.05),
        px: 2,
        py: 4,
        borderRadius: (t) => t.shape.borderRadius,
        width: 400,
        margin: "24px auto",
        ...sx,
      }}
    >
      {icon}
      <Typography variant="bodyBold">{title}</Typography>
      {!!desc && <Typography variant="bodyMedium">{desc}</Typography>}
      {!!afterNode && afterNode}
    </Stack>
  )
}
