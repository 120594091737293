import { Button, Paper, Stack, Typography } from "@mui/material"
import { t } from "i18next"
import { ClaimStatus } from "./ClaimStatus"
import { Link } from "react-router-dom"
import { routeNames } from "navigation"
import { fromWeiToString } from "utils"
import TitleWithDescription from "../TitleWithDescription"
import { ClaimStatusEnum, ClaimType } from "types/claims"
import { AccountTypeEnum } from "hooks/useProjectsAsClaims"
import {
  DisputeResultEnum,
  DisputeStatusEnum,
  DisputeType,
} from "types/dispute"
import TitleValueViewer from "../TitleValueViewer"

type ClaimCardType = {
  claim: ClaimType
  accountType: AccountTypeEnum
}

const ClaimCard = ({ claim, accountType }: ClaimCardType) => {
  let statusEnum = ClaimStatusEnum.waiting

  let navigateLink = `${routeNames.contractor.index}/${
    routeNames.contractor.claims.index
  }/details/${Number(claim.id)}`

  if (accountType === AccountTypeEnum.Owner) {
    navigateLink = `${routeNames.employer.index}/${
      routeNames.employer.claims.index
    }/details/${Number(claim.id)}`
  }

  const relatedDispute = claim?.disputers?.find(
    (d: DisputeType) =>
      d?.contractor?.toLowerCase() === claim.contractor?.toLowerCase()
  )

  const isDisputeVoted =
    Number(relatedDispute?.status) === DisputeResultEnum.Agreed
  const isDisputeRejected =
    Number(relatedDispute?.status) === DisputeResultEnum.Disagreed

  if (claim.approved || isDisputeVoted) {
    statusEnum = ClaimStatusEnum.approved
  } else if (claim.disapproved || isDisputeRejected) {
    statusEnum = ClaimStatusEnum.rejected
  } else if (!claim.approved) {
    statusEnum = ClaimStatusEnum.waiting
  } else {
    statusEnum = ClaimStatusEnum.waiting
  }

  return (
    <Paper
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
      }}
    >
      <Stack
        width="100%"
        justifyContent="space-between"
        spacing={1}
        borderBottom={"1px solid"}
        borderColor={(t) => t.palette.divider}
      >
        <TitleWithDescription
          sxProps={{
            "& p": {
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            },
          }}
          parentSpacing={1.5}
          headerVariant="bodyMedium"
          descriptionVariant="smallMedium"
          descriptionColor="text.disabled"
          parentDir="column"
          title={claim?.title || "-"}
          description={claim?.description || "-"}
        />
        <TitleValueViewer
          title={"Status"}
          value={<ClaimStatus status={statusEnum} />}
        />
        <TitleValueViewer
          title={t("reward")}
          value={
            <Typography variant="smallMedium" color={"text.secondary"}>
              {`${fromWeiToString(claim.value)} ETH`}
            </Typography>
          }
        />
        {accountType === AccountTypeEnum.Owner ? (
          <TitleValueViewer
            title={t("contractor")}
            value={
              <Typography variant="smallMedium" color={"text.secondary"}>
                {`${claim.contractor.slice(0, 6)} **** ${claim.contractor.slice(
                  claim.contractor.length - 6
                )}`}
              </Typography>
            }
          />
        ) : (
          <TitleValueViewer
            title={t("owner")}
            value={
              <Typography variant="smallMedium" color={"text.secondary"}>
                {`${claim?.owner.slice(0, 6)} **** ${claim?.owner.slice(
                  claim?.owner.length - 6
                )}`}
              </Typography>
            }
          />
        )}
      </Stack>
      <Link to={navigateLink}>
        <Button variant="outlined">{t("details")}</Button>
      </Link>
    </Paper>
  )
}

export default ClaimCard
