import { Box, Divider, Stack, Typography } from "@mui/material"
import { ReactNode } from "react"

export type TitleValueType = {
  id: number
  title: string
  value: ReactNode
  hasDivider?: boolean
}

type Props = {
  item: TitleValueType
}

const TitleValue = ({ item }: Props) => {
  return (
    <Box>
      <Stack
        direction="row"
        width={"100%"}
        p={1}
        py={1.5}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="smallRegular" color={"text.primary"}>
          {item.title}
        </Typography>
        {item.value}
      </Stack>
      {item.hasDivider ? <Divider orientation="horizontal" /> : null}
    </Box>
  )
}

export default TitleValue
