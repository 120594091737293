import StatusBox, { EStatusVariant } from "components/shared/StatusBox"
import { ClaimStatusEnum } from "types/claims"
import { t } from "i18next"
import React from "react"

type Props = {
  status: number
}

export const ClaimStatus = ({ status }: Props) => {
  let statusVariant: EStatusVariant
  let statusLabel: string

  switch (status) {
    case ClaimStatusEnum.waiting:
      statusVariant = EStatusVariant.Waiting
      statusLabel = t("waiting")
      break
    case ClaimStatusEnum.approved:
      statusVariant = EStatusVariant.Accepted
      statusLabel = t("approved")
      break
    case ClaimStatusEnum.rejected:
      statusVariant = EStatusVariant.Rejected
      statusLabel = t("disapproved")
      break
    default:
      statusVariant = EStatusVariant.Default
      statusLabel = t("unknown")
      break
  }

  return <StatusBox variant={statusVariant} label={statusLabel} />
}
