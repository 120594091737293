import { DisputeType } from "types/dispute"

export enum ClaimStatusEnum {
  "rejected" = 0,
  "approved" = 1,
  "waiting" = 2,
}

export type ClaimType = {
  0: string
  1: bigint
  2: bigint
  3: bigint
  4: bigint
  5: boolean
  6: boolean
  approved: boolean
  owner?: string
  description?: string
  title?: string
  contractor: string
  disapproved: boolean
  id: bigint
  projectId: bigint
  timestamp: bigint
  disputers?: DisputeType[]
  value: bigint
}
