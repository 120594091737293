import * as React from "react"

export interface IconType {
  color?: string
}

interface IPlusIconProps extends IconType {
  width?: string
  height?: string
}

const PlusIcon: React.FunctionComponent<IPlusIconProps> = ({
  color = "#D4D4D4",
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 -960 960 960"
      width={width}
    >
      <path
        fill={color}
        d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"
      />
    </svg>
  )
}

export default PlusIcon
