import { Stack } from "@mui/material"
import React, { ReactNode } from "react"

type Props = {
  label: ReactNode
  value: number
  progressBar: ReactNode
  percentage: ReactNode
}

const VoteProgressViewer = ({ label, progressBar, percentage }: Props) => {
  let percentNode = (
    <Stack
      sx={{
        borderRadius: 2,
        p: 0.5,
        mb: -0.5,
        px: 3,
        color: (t) => t.palette.common.white,
        bgcolor: (t) => t.palette.extended_colors.status.main,
      }}
    >
      {percentage}
    </Stack>
  )
  return (
    <Stack
      direction={"row"}
      width={"100%"}
      alignItems={"flex-end"}
      justifyContent={"flex-start"}
    >
      <Stack spacing={1} width={"100%"}>
        {label}
        {progressBar}
      </Stack>
      {percentNode}
    </Stack>
  )
}

export default VoteProgressViewer
