import { PayloadAction, createSlice } from "@reduxjs/toolkit"

type AuthState = {
  account: string | null
  type: string | null
}

const initialState: AuthState = {
  account: null,
  type: null,
}

const authSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    setAccount(state, action: PayloadAction<string | null>) {
      state.account = action.payload
    },
    setType(state, action: PayloadAction<string | null>) {
      state.type = action.payload
    },
  },
})

export const { setAccount, setType } = authSlice.actions

export default authSlice.reducer
