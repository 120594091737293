import { MAIN_CONTRACT_ADDRESS } from "consistants"
import { useEffect, useState } from "react"
import SmartContractService from "services/smart-contract-service"
import { ValidSettingsType } from "types/settings"
import { getRevertReason } from "utils"

const EXCLUDE_ITEMS = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "__length__",
]

const useSettings = (
  account: string
): {
  settings: ValidSettingsType
  loading: boolean
} => {
  const [loading, setLoading] = useState(false)

  const [settings, setSettins] = useState<ValidSettingsType>(null)

  useEffect(() => {
    const getSettings = async () => {
      const smartContractService = new SmartContractService(
        account,
        MAIN_CONTRACT_ADDRESS
      )
      try {
        setLoading(true)
        const smartContractRead = await smartContractService.getReadInstance()

        const res = await smartContractRead.setMethodName("settings").do()
        let resKeys = Object.keys(res)
        resKeys = resKeys.filter((item) => {
          return !EXCLUDE_ITEMS.includes(item)
        })
        let convertedObj: any = {}
        for (let item of resKeys) {
          if (Object.keys(res).includes(item)) {
            convertedObj[item] = Number(res[item])
          }
        }
        setSettins(convertedObj)
        setLoading(false)
      } catch (error) {
        getRevertReason(error)
        setLoading(false)
      }
    }

    if (!!!account) return
    getSettings()
  }, [account])

  return {
    settings,
    loading,
  }
}
export default useSettings
