import { ProjectType } from "types/project"
import SmartContractService from "./smart-contract-service"
import { DisputeType } from "types/dispute"
import Web3 from "web3"
import { AccountNumEnum } from "components/shared/Jury/RefereeSidebar/VotingStep/RegisterVoteActions"

const web3 = new Web3((window as any).ethereum as any)

class Signature {
  private smartContractService: SmartContractService
  private project: ProjectType
  private dispute: DisputeType

  constructor(
    smartContractService: SmartContractService,
    project?: ProjectType,
    dispute?: DisputeType
  ) {
    this.smartContractService = smartContractService
    if (!!project) this.project = project
    if (!!dispute) this.dispute = dispute
  }

  private async encodeParams(
    type: AccountNumEnum,
    nonce: string,
    projectId: BigInt,
    disputeId: BigInt
  ) {
    const smartContractRead = await this.smartContractService.getReadInstance()

    const res = await smartContractRead
      .setMethodName("EncodeParams")
      .setArgs([type, nonce, Number(projectId), Number(disputeId)])
      .do()

    return res
  }
  private async paramsToHash(encodedParams: string) {
    const smartContractRead = await this.smartContractService.getReadInstance()
    const res = await smartContractRead
      .setMethodName("ParamsToHash")
      .setArgs([encodedParams])
      .do()

    return res
  }

  async sign(type: AccountNumEnum, nonce: string, account: string) {
    const project = this.project
    const dispute = this.dispute
    if (!!!project || !!!dispute) return
    const encodedParams = await this.encodeParams(
      type,
      nonce,
      project.id,
      dispute.id
    )
    const hashedParams = await this.paramsToHash(encodedParams)
    const signPassword = hashedParams
    return await web3.eth.personal.sign(hashedParams, account, signPassword)
  }
}

export default Signature
