import { Box, Breadcrumbs, Typography } from "@mui/material"
import { ReactNode } from "react"
import { Link } from "react-router-dom"

export type BreadcrumbItemType = {
  title: string
  href?: string
}

interface Props {
  items: BreadcrumbItemType[]
  separator?: ReactNode
}

const SmBreadcrumbs = ({ items, separator = "/" }: Props) => {
  return (
    <Breadcrumbs
      sx={{
        "& a": {
          textTransform: "capitalize",
          "&:hover": {
            color: (t) => t.palette.secondary.main,
          },
          color: (t) => t.palette.secondary.light,
          transition: "color 0.08s ease-in-out",
          fontSize: "14px",
          fontWeight: 400,
        },
      }}
      aria-label="breadcrumb"
      separator={separator}
    >
      {items.map((item, i) => {
        let content = (
          <Typography
            variant="smallRegular"
            sx={{
              transition: "color 0.08s ease-in-out",
              textTransform: "capitalize",
              cursor: "default",
              "&:hover": {
                color: (t) => t.palette.secondary.main,
              },
            }}
          >
            {item.title}
          </Typography>
        )
        if (item.href) {
          content = <Link to={item.href}>{item.title}</Link>
        }
        return <Box key={i + 1}>{content}</Box>
      })}
    </Breadcrumbs>
  )
}
export default SmBreadcrumbs
