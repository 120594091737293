import {
  LinearProgressProps,
  LinearProgress,
  Tooltip,
  SxProps,
  Theme,
} from "@mui/material"
import { useRef } from "react"

type Props = {
  showLabel?: boolean
  tooltipLabel?: string
  sx?: SxProps<Theme>
}

type CustomLinearType = Props & LinearProgressProps

const CustomLinearProgress = ({
  showLabel = false,
  tooltipLabel = "label",
  sx = {},
  value,
  ...rest
}: CustomLinearType) => {
  const positionRef = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })
  const popperRef = useRef<any>(null)
  const areaRef = useRef<HTMLDivElement>(null)

  const handleMouseMove = (event: React.MouseEvent) => {
    const progressBarRect = areaRef.current!.getBoundingClientRect()
    const mouseX = event.clientX - progressBarRect.left // Adjust mouse position relative to the progress bar
    const progressBarWidth = progressBarRect.width

    const progressPercentage = (mouseX / progressBarWidth) * 100

    if (progressPercentage <= value) {
      positionRef.current = { x: event.clientX, y: event.clientY }
      if (popperRef.current != null) {
        popperRef.current.update()
      }
    }
  }

  let currentView = (
    <LinearProgress
      sx={{ my: 1, ...sx }}
      ref={areaRef}
      variant="determinate"
      value={value ?? 0}
      onMouseMove={handleMouseMove}
      {...rest}
    />
  )

  if (showLabel) {
    return (
      <Tooltip
        title={tooltipLabel}
        placement="top"
        arrow
        PopperProps={{
          popperRef,
          anchorEl: {
            getBoundingClientRect: () => {
              return new DOMRect(
                positionRef.current.x,
                areaRef.current!.getBoundingClientRect().y - 10,
                0,
                0
              )
            },
          },
        }}
      >
        {currentView}
      </Tooltip>
    )
  }
  return <>{currentView}</>
}

export default CustomLinearProgress
