import * as React from "react"

export interface IconType {
  color?: string
}

interface INavigateRightProps extends IconType {
  size?: number
}

const NavigateRight: React.FunctionComponent<INavigateRightProps> = ({
  color = "#FEFEFE",
  size = 16,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 2.72003L10.3467 7.0667C10.86 7.58003 10.86 8.42003 10.3467 8.93336L6 13.28"
        stroke={color}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default NavigateRight
