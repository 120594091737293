const getTimeformat = (
  seconds: number,
  hasHours: boolean = false,
  hasDays: boolean = false
) => {
  const days = Math.floor(seconds / 86400)
  const hours = Math.floor((seconds % 86400) / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = Math.floor(seconds % 60)

  const formattedHours = hours.toString().padStart(2, "0")
  const formattedDays = days.toString().padStart(2, "0")
  const formattedMinutes = minutes.toString().padStart(2, "0")
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0")

  let formattedTime = `${formattedMinutes}:${formattedSeconds}`
  let labeledTime = `${formattedMinutes}m:${formattedSeconds}s`

  if (hasHours) {
    if (!!hours) {
      formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
      labeledTime = `${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s`
    }
  }
  if (hasDays) {
    if (!!days) {
      formattedTime = `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`
      labeledTime = `${formattedDays}d:${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s`
    }
  }
  return { time: formattedTime, labeledTime }
}

export default getTimeformat
