import {
  Box,
  Skeleton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  SxProps,
  Theme,
} from "@mui/material"
import CustomStepIcon from "./CustomStepIcon"
import { ReactNode } from "react"
import CustomStepConnector from "./CustomStepConnector"

export type NormalStepperType = {
  steps: {
    label?: string
    icon?: ReactNode
    optionalNode?: ReactNode
    description?: string
  }[]
  hasTooltip?: boolean
  sxProps?: SxProps<Theme>
  loading?: boolean
  activeStep: number
}

const NormalCustomStepper = ({
  steps,
  sxProps,
  activeStep,
  hasTooltip = false,
  loading = false,
}: NormalStepperType) => {
  const optionalNode = (children: ReactNode) => {
    return (
      <Box
        sx={{
          position: "absolute",
          transform: "translate(50%,100%)",
          right: 0,
          bottom: "100%",
          mb: "10px",
        }}
      >
        {children}
      </Box>
    )
  }

  const skeletonNode = (
    <Skeleton
      width={80}
      sx={{ backgroundColor: (t) => t.palette.extended_colors.status.main }}
    />
  )
  return (
    <Stack className="normal-stepper" sx={{ width: "100%", ...sxProps }}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<CustomStepConnector loading={loading} />}
      >
        {steps.map((step, index) => {
          const isActive = index === activeStep
          const showOptionalNode = isActive && !!step?.optionalNode
          const titleNode = optionalNode(step?.optionalNode)

          return (
            <Step key={index + 1}>
              <StepLabel
                sx={{
                  "& .MuiStepLabel-labelContainer": {
                    display: "flex",
                    justifyContent: "center",
                  },
                }}
                {...(showOptionalNode && {
                  optional: loading ? skeletonNode : titleNode,
                })}
                StepIconComponent={(props) => (
                  <CustomStepIcon
                    {...props}
                    description={step?.description}
                    loading={loading}
                    hasTooltip={hasTooltip}
                    icon={loading ? <></> : step?.icon}
                  />
                )}
              >
                {loading ? skeletonNode : step?.label ?? ""}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </Stack>
  )
}

export default NormalCustomStepper
