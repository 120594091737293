import { ProjectType } from "types/project"
import SmartContractService from "./smart-contract-service"
import { convertToWei } from "utils/fromWeiToString"

export type ProjectParamsType = {
  description: string
  duration: string
  reward: number
  contractorFee: number
  value: number
  title: string
}

class Project {
  private smartContractService: SmartContractService

  private project: ProjectType

  constructor(
    smartContractService: SmartContractService,
    project?: ProjectType
  ) {
    this.smartContractService = smartContractService
    if (!!project) this.project = project
  }
  async createProject(params: ProjectParamsType) {
    const smartContractWrite =
      await this.smartContractService.getWriteInstance()

    const res = await smartContractWrite
      .setMethodName("createProject")
      .setArgs([
        Math.round(new Date().getTime() / 1000) + params.duration,
        convertToWei(params.reward),
        convertToWei(params.contractorFee),
        params.description,
        params.title,
      ])
      .setValue(params.value)
      .do()

    return res
  }
  async getAllProjects() {
    const smartContractRead = await this.smartContractService.getReadInstance()
    const res = await smartContractRead.setMethodName("getProjects").do()
    return res
  }
  async getProjectById(id: string) {
    const smartContractRead = await this.smartContractService.getReadInstance()
    const res = await smartContractRead
      .setMethodName("getProjectById")
      .setArgs([Number(id)])
      .do()
    return res
  }
  async apply() {
    if (!!!this.project) return

    const project = this.project
    const smartContractWrite =
      await this.smartContractService.getWriteInstance()
    const contractorFee = project.registrationFee
    const res = await smartContractWrite
      .setMethodName("registerAsContractor")
      .setArgs([project.id])
      .setValue(contractorFee, false)
      .do()

    return res
  }

  async closeProject() {
    if (!!!this.project) return

    const project = this.project

    const smartContractWrite =
      await this.smartContractService.getWriteInstance()

    const res = await smartContractWrite
      .setMethodName("closeProject")
      .setArgs([Number(project.id)])
      .do()
    return res
  }
}

export default Project
