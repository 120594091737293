import { Divider, Grid, Paper, Skeleton, Stack } from "@mui/material"

interface Props {}

const DisputesListSkeleton = (props: Props) => {
  return (
    <Grid container spacing={2}>
      {[...Array.from(Array(3)).keys()].map((item) => {
        return (
          <Grid key={item + 1} item xs={12} md={6} lg={4}>
            <Paper sx={{ p: 2, pt: 3 }}>
              <Stack spacing={2} alignItems={"center"} sx={{ width: "100%" }}>
                <Stack width={"100%"} spacing={2}>
                  <Skeleton sx={{ width: 150, height: 28 }} variant="text" />
                  <Stack>
                    <Skeleton sx={{ width: 200, height: 28 }} variant="text" />
                    <Skeleton sx={{ width: 300, height: 28 }} variant="text" />
                  </Stack>
                </Stack>
                <Divider sx={{ width: "100%" }} />
                <Stack width={"100%"}>
                  {[...Array.from(Array(4)).keys()].map((item) => {
                    return (
                      <>
                        <Stack
                          key={item + 1}
                          direction="row"
                          width={"100%"}
                          p={1}
                          py={1.5}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Skeleton
                            sx={{ width: 150, height: 26 }}
                            variant="rounded"
                          />
                          <Skeleton
                            sx={{ width: 150, height: 26 }}
                            variant="rounded"
                          />
                        </Stack>
                        {item < 3 && <Divider sx={{ width: "100%" }} />}
                      </>
                    )
                  })}
                </Stack>
                <Skeleton sx={{ width: 150, height: 33 }} variant="rounded" />
              </Stack>
            </Paper>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default DisputesListSkeleton
