const splitArrayBySpecificLength = (arr: any[], length: number) => {
  return arr.reduce((acc, item, index) => {
    const chunkIndex = Math.floor(index / length)
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = []
    }
    acc[chunkIndex].push(item)
    return acc
  }, [])
}

export default splitArrayBySpecificLength
