import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { IconButton, Stack } from "@mui/material";
type Props = {
  title: string;
  onClose: () => void;
};
export default function Heading({ title, onClose }: Props) {
  return (
    <Stack alignItems={"center"} sx={{ py: 2, px: 1, position: "relative" }}>
      <IconButton
        sx={{
          position: "absolute",
          left: 2,
          top: "50%",
          transform: "translateY(-50%)",
        }}
        onClick={onClose}
      >
        <CloseOutlined sx={{ color: (t) => t.palette.common.white }} />
      </IconButton>
      {title}
    </Stack>
  );
}
