import VoteProgressViewer from "."
import CustomLinearProgress from "../CustomLinearProgress"
import { Typography } from "@mui/material"

type Props = {
  label: string
  value: number
  tooltipValue: string
}

const PrimaryProgressViewer = ({ label, value, tooltipValue }: Props) => {
  const flattedValue = Math.floor(value)

  return (
    <VoteProgressViewer
      value={value}
      label={
        <Typography variant="smallRegular" color={"text.disabled"}>
          {label}
        </Typography>
      }
      progressBar={
        <CustomLinearProgress
          sx={{ width: 300 }}
          showLabel
          tooltipLabel={tooltipValue}
          value={value}
          color="primary"
        />
      }
      percentage={
        <Typography
          variant="smallRegular"
          color={"common.white"}
        >{`${flattedValue}%`}</Typography>
      }
    />
  )
}

export default PrimaryProgressViewer
