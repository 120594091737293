import { Divider, Grid, Paper, Skeleton, Stack } from "@mui/material"

interface Props {}

export const ClaimsListSkeleton = (props: Props) => {
  return (
    <Grid container spacing={2}>
      {[...Array.from(Array(6)).keys()].map((item) => {
        return (
          <Grid xs={12} md={6} lg={4} item key={item + 1}>
            <Paper
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
              }}
            >
              <Stack alignItems={"center"} spacing={1}>
                <Skeleton sx={{ width: 200, height: 18 }} variant="rounded" />
                <Skeleton sx={{ width: 300, height: 18 }} variant="rounded" />
              </Stack>
              <Stack sx={{ width: "100%" }} spacing={1}>
                {[...Array.from(Array(3)).keys()].map((item) => {
                  return (
                    <Stack
                      key={item + 1}
                      direction={"row"}
                      width={"100%"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Skeleton
                        sx={{ width: 100, height: 28 }}
                        variant="rounded"
                      />
                      <Skeleton
                        sx={{ width: 100, height: 28 }}
                        variant="rounded"
                      />
                    </Stack>
                  )
                })}
              </Stack>
              <Divider sx={{ width: "100%" }} />
              <Skeleton sx={{ width: 150, height: 33 }} variant="rounded" />
            </Paper>
          </Grid>
        )
      })}
    </Grid>
  )
}
