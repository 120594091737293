import { Box, CircularProgress } from "@mui/material"
import SearchBox from "./SearchBox"
import { useCallback, useState } from "react"
import { useEffectAvoidFirst } from "hooks"
let timeoutId: NodeJS.Timeout

interface Props {
  onChange?: (value: string) => void
  placeholder?: string
}

const TurtleSearchBox = ({ onChange, placeholder }: Props) => {
  const [loading, setLoading] = useState(false)

  const [value, setValue] = useState("")

  const changeValueHandler = useCallback((val: string) => {
    setValue(val)
  }, [])
  useEffectAvoidFirst(() => {
    setLoading(true)
    const handleTimeout = () => {
      setLoading(false)
      if (onChange) onChange(value)
    }
    clearInterval(timeoutId)
    timeoutId = setTimeout(handleTimeout, 1000)
    return () => {
      clearInterval(timeoutId)
    }
  }, [value, onChange])

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <SearchBox
        loading={loading}
        placeholder={placeholder}
        onChange={changeValueHandler}
      />
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: 16,
          }}
        >
          <CircularProgress sx={{ mt: 1 }} size={22} />
        </Box>
      )}
    </Box>
  )
}
export default TurtleSearchBox
