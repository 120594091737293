class SmartContractRead {
  private account: string
  private smartContract: any
  private methodName: string
  private args: any[] = []

  constructor(account, smartContract: any) {
    this.account = account
    this.smartContract = smartContract
  }

  public setMethodName(name: string) {
    this.methodName = name
    return this
  }

  public setArgs(args: any[]) {
    this.args = args
    return this
  }

  public async do() {
    const account = this.account
    let payload: { [key: string]: any } = {
      from: account,
    }
    let argsToSend = { ...this.args }

    const res = await this.smartContract?._methods[this.methodName]?.(
      ...Object.values(argsToSend)
    ).call(payload)

    return res
  }
}

export default SmartContractRead
