import { lightPalette } from "mui/palettes"
import VoteProgressViewer from "."
import CustomLinearProgress from "../CustomLinearProgress"
import { Typography } from "@mui/material"

type Props = {
  label: string
  value: number
  tooltipValue: string
}

const SuccessProgressViewer = ({ label, value, tooltipValue }: Props) => {
  const flattedValue = Math.floor(value)

  return (
    <VoteProgressViewer
      value={value ?? 0}
      label={
        <Typography variant="smallRegular" color={"extended_colors.green.main"}>
          {label}
        </Typography>
      }
      progressBar={
        <CustomLinearProgress
          sx={{ width: 300 }}
          showLabel
          tooltipLabel={tooltipValue}
          value={value}
          color="success"
        />
      }
      percentage={
        <Typography
          variant="smallRegular"
          color={"extended_colors.green.main"}
        >{`${flattedValue}%`}</Typography>
      }
    />
  )
}

export default SuccessProgressViewer
